<script type="text/javascript">

</script>

<template>
	<div class="">
		<!--:class="{ 'cockpitview':stagesizestate==0, 'cockpitviewnarrow':stagesizestate==1&&sidebarexpanded==false }"-->
		<div class="content-page cockpitview">

		<b-modal v-model="publishmodalshow" @hidden="onpublisheddone" id="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Publish report</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="publishmodalshow = false"></i>
	          </a>
	        </template>


	        <div class="" id="publish" >

						<div v-bind:class="{'hide':page!='stillloading'}">
							<i class="fa fa-circle-o-notch fa-spin"></i>
						</div>
						<div class="noshadow" id="newslettersteps" v-if="!publisheddone">
							<div class="text-center">
								<p>You are about to publish the report:</p>
								<h3 style = "text-align: center !important">{{ newsletter.name }} ({{ newsletter.periodstart | timeFormat }} - {{ newsletter.periodend | timeFormat }}) </h3>

								<p class="" >When you publish this report it will be made available online and all user with access to this project will be able to see it. It is not possible to edit the contents of a report once it has been published.</p>

								<div class="d-flex align-items-center justify-content-center">

								<div class="col-1 w-100 checkboxgroup">
				                  <span>
				                    <input type="checkbox" class="statusoptiondefaults" ref="statuscheckbox" v-model="sendnotification" value="true">
				                    <label id="checkboxlabel" for="checkbox" tabindex=3></label>
				                    
				                  </span>
				                </div>

				                <div class="pt-2">Send an email notification to all users with access to the project.</div>

				           		 </div>
							


							</div>
						</div>

						<div id="newsletterpublishedconfirmation" class="card-body" v-if="1==2 && publisheddone">
							<div class="noshadow">
								<div class="text-center" >
									<h2 style = "text-align: center !important" class="mt-3">Success!</h2>

									<a @click.prevent="opennewsletterforviewing(newsletter._id); "><h3 style = "text-align: center !important" class="mt-3">{{ newsletter.name }} ({{ newsletter.periodstart | timeFormat }} to {{ newsletter.periodend | timeFormat }})</h3></a>

									<p class="mt-3">You can click on the report title to read the report and check any feedback that's coming in.</p>
                                      </div>
                                  </div>
                              </div>

                          </div>


	        <template v-slot:modal-footer="{ cancel, ok }">
	        	<button v-if="!publisheddone" v-on:click="dopublish( newsletter._id ); " class="btn btn-primary float-center">Publish report</button>
	        	<button v-if="publisheddone" v-on:click="publishmodalshow=false" class="btn btn-primary float-center">Done</button>
	        </template>
	    </b-modal>

	    <cockpitdescription 
		  :shown="cockpitdescriptionmodalshown"
		  @onmodalhidden  = "onmodaldescriptionhidden"
		  ></cockpitdescription>

		<b-modal @shown="previewmodalshown" @hidden="previewmodalhidden" id="previewmodal" v-model="previewmodalshow" hide-footer no-close-on-esc no-close-on-backdrop size="lg">

			<template v-slot:modal-header>
	          <h3 class="modal-title">Preview</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="previewmodalshow = false"></i>
	          </a>
	        </template>

		    <div class="p-t-20" id="preview">

						<div v-bind:class="{'hide':page!='stillloading'}">
							<i class="fa fa-circle-o-notch fa-spin"></i>
						</div>
						<div v-bind:class="{'hide':page=='stillloading'}">
							<!-- preview -->
						<div class="buttons text-center mb-2">

					      <b-form-radio-group
					        id="btn-radios"
					        v-model="preview"
					        :options="radionoptions"
					        buttons
					       	button-variant="outline-primary"
					        name="radio-btn-outline"
					      ></b-form-radio-group>
					</div>
							<div class="card2 mb-0 mx-auto" >

								

								<div class="card-body2">

									<div class="br-n d-flex">
										<div id="viewindesktop" v-if="preview=='desktop'" class="w-100" >
											<div id="monitorframe" style="margin: auto;" class="border p-2" >
												<div class = "py-3 N100-b"  style="padding-left: 20px; padding-right: 20px;">
												<h3 class ="mb-0">{{newsletter.name}}</h3>
												<h4 class=" font-weight-bold " style="display: inline;">{{cockpit.name}}  </h4>
													
												</div>
												<div class="sensor"></div>
												<div  class="img-responsive pt-3" style= "padding:0 40px;" v-html="$options.filters.bindevents(newsletterviewerdata)">
												</div>
												<div class="alert alert-info fade show text-center">Like, dislike and comments section will be added here.</div>
											</div>
										</div>
										<div class="phoneBox align-self-center" v-if="preview=='mobile' && 1==2" >
											<div class="phoneBoxisland"></div>
											
											<div id="phonescreen" class = "p-2" v-html="$options.filters.bindevents(newsletterviewerdata)"></div>

										</div>
										<div class=" w-100" id="viewinmobilecockpit" v-if="preview=='mobile'">
											<div id="mobileframe" class="align-self-center">
												<div class="sensor"></div>
												<div class="speaker"></div>
												<div class="img-responsive pt-2 phoneBoxold" style="max-height: 500px; overflow: scroll;">
													<div class = " py-3 N100-b"  style="margin-left:-20px; margin-right:-20px; margin-top:-20px; padding-left: 20px; padding-right: 20px;">
												<h3 class ="mb-0">{{newsletter.name}}</h3>
												<h4 class=" font-weight-bold " style="display: inline;">{{cockpit.name}}  </h4>
													
												</div>
												
												<div id="phonescreen" class="pt-2 phoneBoxold" v-html="$options.filters.bindevents(newsletterviewerdata)"></div>
													<div class="alert alert-info fade show text-center">Like, dislike and comments section will be added here.</div>
												</div>

												<div class="homebutton"></div>
											</div>
										</div>
									</div>                               

								</div>
							</div>


							<!-- -->
						</div>

					</div>
		 </b-modal>


		<b-modal @shown="modalexportingmodalshown" @hidden="modalexportingmodalhidden" id="modalexporting" v-model="exportingtoword" hide-footer hide-header  no-close-on-esc no-close-on-backdrop>

			<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="exportingtoword = false" ></i>
				</a>

		    <p style="text-align: center;"> 
		    	<a :download="generatedlinkfilename" ref="generatedlink" v-if="exportedwordlocation!=''" :href="exportedwordlocation" id="exportingtowordlocation">Download link</a>
		    	<span v-else><i class="fa fa-circle-o-notch fa-spin"></i> Generating word document</span>
		   	</p>
		 </b-modal>

		<b-modal ref = "newslettermodal" no-close-on-backdrop no-close-on-esc no-fade size="xs-p" centered v-model="showdeleteactivenewsletter ">
			
			<template v-slot:modal-header>


				<h3 class="modal-title"><span>Delete draft report</span></h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="showdeleteactivenewsletter = false" ></i>
				</a>
			</template>
			Click 'Delete' to permanently delete this draft report. <br>Note that deleted reports cannot be recovered.


			<template v-slot:modal-footer="{ cancel, ok }" >
				<button-gruppe text1="Delete"  @no ="showdeleteactivenewsletter = false" @yes = "deleteactivenewsletter(newsletter)"></button-gruppe>

			</template>



		</b-modal>


		<div
		id="newslettermodalid">

		



<!--     -->
<div id="progressstatusreporttemplate" v-if="cockpit && reportlang=='dk'" style="visibility:hidden; position:absolute; width:900px; top:-1500000px"  >

<h1>Projekt status rapport</h1>
<table style="width: 100%;">
<tbody>
	<tr>
		<td style="width: 25%;"><div style="text-align: left;">Projekt:</div></td>
		<td style="width: 75%;">{{ cockpit.name }}</td>
	</tr>
	<tr><td style="width: 25%;"><div style="text-align: left;">Rapport:</div></td>
		<td style="width: 75%;">{{ newslettertitle }} </td>
	</tr>
	<tr>
		<td style="width: 25%;"><div style="text-align: left;">Periode:</div></td>
		<td style="width: 75%;">{{ timeperiodstart  }} - {{ timeperiodend  }}</td></tr>
	<tr>
		<td style="width: 25%;"><div style="text-align: left;">Forfatter:</div></td>
		<td style="width: 75%;">{{ loggedinuser.firstname }} {{ loggedinuser.lastname }}</td>
	</tr>
	</tbody>
</table>

<br>

<p>[ Kære Alle, ]<br>[ Skriv velkomst paragraph her…  ]</p>

<template v-for="(section, index ) in cockpit.sections">
<h3 :key="'section'+index" v-if="section.objectives && section.objectives.length>0"><strong>{{ section.label }}</strong></h3>

<template v-for="(objective, index ) in section.objectivesdata">
<p  :key="'objectivesection_'+index+objective._id">

<img v-if="objective.indicators && objective.indicators[ objective.indicators.length - 1] && objective.noaccess == false" :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii">
<img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess ==true">[No access]</span>



</p>
</template>

</template>

<h3 v-if="cockpit.measuresnotinsections && cockpit.sections && cockpit.measuresnotinsections.length>0 && cockpit.sections.length > 0"><strong>Not grouped</strong></h3>

<template v-for="(objective, index ) in cockpit.measuresnotinsections">
<p :key="'objectivenotsection'+index"><img v-if="objective.indicators[ objective.indicators.length - 1 ] && objective.noaccess == false" :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii">
<img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess ==true">[No access]</span> </p>
	
</template>

<br>
<!-- page_break -->

<h2>Status på projektets mål</h2>

<p>[ Tilføj et par sætninger der beskriver status… ]</p>

<div class="fr-img-space-wrap objectivesgraphimagedk" id="objectivesgraphimagedk">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'objectivesgraphimagedk')" @error="imagewitherror($event, 'objectivesgraphimagedk')" :src="objectivesgraphimage" style="width: 621px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Gennemsnitsudvikling af projektets mål fra projekt start</span>
</span>
</span>
</div>

<template v-for="section in cockpit.sections">

<template v-for="(objective, index ) in section.objectivesdata">

	
 <h3 :key="'oh2'+index+objective._id"><img :key="'oimg'+index" v-if="objective.indicators && objective.indicators[ objective.indicators.length - 1 ] && objective.noaccess == false " :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii"><img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess">[No access]</span></h3>

 
 <template v-if="!objective.noaccess">[ Skriv en kort opsummering af status for dette mål ]</template>

<template v-if="!objective.noaccess">
	<template v-for="(indicator, indexindicator ) in objective.indicators" > 

		<p v-if="objective.measuretype == 'measure' && ( new Date(indicator.indicatordate) > new Date(timeperiodstart) && new Date(indicator.indicatordate) < new Date(timeperiodend) )" :key="indicator._id + objective._id + indexindicator"> ({{ indicator.userdetail.firstname }} {{ indicator.userdetail.lastname }} - {{ momentdate(indicator.indicatordate) }} ) <span :key="'message_'+indicator._id" v-html="indicator.message"></span>

			<br :key="'brmessage_'+indicator._id">
		</p>

	</template>
</template>


</template>



</template>


<template v-for="(objective, index ) in cockpit.measuresnotinsections">


<div v-if="cockpit.measuresnotinsections && cockpit.measuresnotinsections.length > 0" :key="'nosectionstatus' + index">
	
 <h3 :key="'oh2'+index"><img :key="'oimg'+index" v-if="objective.indicators && objective.indicators[ objective.indicators.length - 1 ] && objective.noaccess == false" :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii"><img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess">[No access]</span></h3>

<template v-if="!objective.noaccess">[ Skriv en kort opsumeering af status for dette mål ]</template>

<template v-if="!objective.noaccess">
	<template v-for="(indicator, indexindicator ) in objective.indicators" > 

		<p v-if="objective.measuretype == 'measure' && ( new Date(indicator.indicatordate) > new Date(timeperiodstart) && new Date(indicator.indicatordate) < new Date(timeperiodend) ) " :key="indicator._id + objective._id + indexindicator"> ({{ indicator.userdetail.firstname }} {{ indicator.userdetail.lastname }} - {{ momentdate(indicator.indicatordate) }} ) <span :key="'message_'+indicator._id" v-html="indicator.message"></span>
			<br :key="'br2'+index">
		</p>

	</template>
</template>


</div>

</template>

<div v-if="cockpit.masterplan">

<br>
<!-- page_break -->

<h2>Projekt masterplan</h2>

<p>[ Tilføj et par sætninger der beskriver status… ]</p>

<div class="fr-img-space-wrap masterplanimagedk" id="masterplanimagedk">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'masterplanimagedk')" @error="imagewitherror($event, 'masterplanimagedk')" :src="masterplanimage" style="width: 621px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Projekt masterplan</span>
</span>
</span>
</div>

<br>
<h2>Milepæle i rapport perioden</h2>

<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Track</td>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Milepæl</td>
			<td style="width: 20.0000%; background-color: rgb(204, 204, 204);">Dato</td>
			<td nowrap="nowrap" style="width: 20.0000%; background-color: rgb(204, 204, 204); text-align:center;">Status</td>
		</tr>
		<template v-if="currentmilestones && currentmilestones.length>0">
		<tr v-for="(milestone, index ) in currentmilestones" :key="index" >
			
			<td style="width: 30.0000%;"><div>{{milestone.tracklabel}}</div></td>
			<td style="width: 30.0000%;">{{milestone.label}}</td>
			<td nowrap="nowrap" style="width: 20.0000%;">{{milestone.eventdate}}</td>
			<td nowrap="nowrap" style="width: 20.0000%; text-align:center;">
				<img height="12px" :src="'https://app.flowe.io/static/img/dot_'+milestone.status+'.png'" class="fr-fic fr-dii">
			</td>	
			
		</tr>
		</template>
		<template v-else>
		<tr>
			<td colspan="4"><div>Der er ingen milepæle i rapport perioden</div></td>
		</tr>
		</template>
	</tbody>
</table>

<br>

<h3>Fremtidige milepæle</h3>

<table style="width: 100%;" width="100%" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Track</td>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Milepæl</td>
			<td style="width: 20.0000%; background-color: rgb(204, 204, 204);">Dato</td>
			<td nowrap="nowrap" style="width: 20.0000%; background-color: rgb(204, 204, 204); text-align:center;">Status</td>
		</tr>
		<template v-if="futuremilestones && futuremilestones.length>0">
		<tr v-for="(milestone, index ) in futuremilestones" :key="index">
			
			<td style="width: 30.0000%;"><div>{{milestone.tracklabel}}</div></td>
			<td style="width: 30.0000%;">{{milestone.label}}</td>
			<td nowrap="nowrap" style="width: 20.0000%;">{{milestone.eventdate}}</td>
			<td nowrap="nowrap" style="width: 20.0000%; text-align:center;">
				<img height="12px" :src="'https://app.flowe.io/static/img/dot_'+milestone.status+'.png'" class="fr-fic fr-dii">
			</td>
			
		</tr>
		</template>
		<template v-else>
		<tr>
			<td colspan="4"><div>No Milestones found</div></td>
		</tr>
		</template>
	</tbody>
</table>

</div>

<div v-if="cockpit.risks">



<br>

<!-- page_break -->

<h2>Projekt risici</h2>

<p>[ Tilføj et par sætninger der beskriver status for projektets risici… ]</p>


<div class="fr-img-space-wrap riskgraphimagedk" id="riskgraphimagedk">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 421px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'riskgraphimagedk')" @error="imagewitherror($event, 'riskgraphimagedk')" :src="riskgraphimage" style="width: 421px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Projekt risiko matrice</span>
</span>
</span>
</div>

<br>

<h3>Top 3 risici</h3>

<p>Disse er the risici med den højeste Konsekvens x sandsynlighed</p>

<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Risiko</td>
			<td style="width: 35%; background-color: rgb(204, 204, 204);">Mitigerende handling</td>
			<td style="width: 20%; background-color: rgb(204, 204, 204);">Ejer</td>
			<td style="width: 15%; background-color: rgb(204, 204, 204);text-align:center;">CxP indikator</td>
		</tr>
		<tr v-if="riskstop && riskstop.length == 0 ">
			<td colspan="5"><div>Ingen risici oprettet.</div></td>
		</tr>
		<tr v-for="(risk, index ) in riskstop" :key="index">
			<td style="width: 30%;"><div>{{ risk.label }}</div></td>
			<td style="width: 35%;word-break: break-word;">{{ risk.mitigatingaction }}</td>
			<td style="width: 20%;" nowrap="nowrap">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
			<td style="width: 15%; text-align:center;"><img height="12px" :src="'https://app.flowe.io/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
		</tr>
	</tbody>
</table>

<br>


<h3>3 nærmeste risici</h3>


<p>Dette er de 3 risici nærhedsdato (proximity) efter rapportperiodens slut dato</p>


<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 25%; background-color: rgb(204, 204, 204);">Risiko</td>
			<td style="width: 25%; background-color: rgb(204, 204, 204);">Mitigerende handling</td>
			<td style="width: 20%; background-color: rgb(204, 204, 204);">Ejer</td>
			<td style="width: 15%; background-color: rgb(204, 204, 204);text-align:center;">CxP indikator</td>
			<td style="width: 15%; background-color: rgb(204, 204, 204);">Nærhedsdato</td>
		</tr>
		<tr v-if="risksclose && risksclose.length == 0 ">
			<td colspan="5"><div>Der findes ikke risici med en nærhedsdato</div></td>
		</tr>
		<tr v-else v-for="(risk, index ) in risksclose" :key="index">
			<td style="width: 25%;"><div>{{ risk.label }}</div></td>
			<td style="width: 25%;word-break: break-word;">{{ risk.mitigatingaction }}</td>
			<td style="width: 20%;">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
			<td style="width: 15%;text-align:center;"><img height="12px" :src="'https://app.flowe.io/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
			<td style="width: 15%;" nowrap="nowrap">{{ risk.proximity }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Risici uden mitigerende handling teller nærhedsdato (Proximity)</h3>

<p>Følgende risici har hverken mitigerende handling eller en nærhedsdato.</p>

<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Risiko</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Ejer</td>
			<td style="width: 20%; background-color: rgb(204, 204, 204);text-align:center;">CxP indikator</td>
		</tr>
		<tr v-if="risksother && risksother.length == 0 ">
			<td colspan="3"><div>Ingen risici uden mitigerende handling og nærhedsdato.</div></td>
		</tr>
		<tr v-else v-for="(risk, index ) in risksother" :key="index">
			<td style="width: 30%;"><div>{{ risk.label }}</div></td>
			<td style="width: 40%;">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
			<td style="width: 20%;text-align:center;"><img height="12px" :src="'https://app.flowe.io/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
		</tr>
	</tbody>
</table>

<br>
<!-- page_break -->

<h3>Udvikling i risikoprofil</h3>


<div class="fr-img-space-wrap riskchartimagedk" id="riskchartimagedk">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'riskchartimagedk')" @error="imagewitherror($event, 'riskchartimagedk')" :src="riskchartimage" style="width: 621px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Projektets udvikling i risikoprofil</span>
</span>
</span>
</div>

</div>

<div v-if="cockpit.stakeholders">

<br>
<!-- page_break -->	

<h2>Interessentstyring</h2>
<p>[ Tilføj et par sætninger der beskriver status for projektets interessentstyring… ]</p>

<div class="fr-img-space-wrap stakeholderimagedk" id="stakeholderimagedk">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 421px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'stakeholderimagedk')" @error="imagewitherror($event, 'stakeholderimagedk')" :src="stakeholderimage" style="width: 421px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Interessentkort</span>
</span>
</span>
</div>

<h3>Tæt styring</h3>

<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Interessent</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Kontakter</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Interessent handling</td>
		</tr>

		<tr v-if="stakeholdersmanage && stakeholdersmanage.length==0">
			<td colspan="3"><div>Der er ikke interessenter i denne kvadrant.</div></td>
		</tr>

		
		<tr v-else v-for="(stakeholder, index ) in stakeholdersmanage" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;">
				<span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span>
			 </td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Hold informeret</h3>

<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Interessent</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Kontakter</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Interessent handling</td>
		</tr>

		<tr v-if="stakeholdersinformed && stakeholdersinformed.length==0">
			<td colspan="3"><div>Der er ikke interessenter i denne kvadrant.</div></td>
		</tr>

		<tr v-else v-for="(stakeholder, index ) in stakeholdersinformed" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}</span><br></td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Hold tilfredse</h3>

<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Interessent</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Kontakter</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Interessent handling</td>
		</tr>

		<tr v-if="stakeholderssatisfied && stakeholderssatisfied.length==0">
			<td colspan="3"><div>Der er ikke interessenter i denne kvadrant.</div></td>
		</tr>
		<tr v-else v-for="(stakeholder, index ) in stakeholderssatisfied" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span></td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Overvåg</h3>

<table style="width: 100%;" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Interessent</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Kontakter</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Interessent handling</td>
		</tr>
		<tr v-if="stakeholdersmonitor && stakeholdersmonitor.length==0">
			<td colspan="3"><div>Der er ikke interessenter i denne kvadrant.</div></td>
		</tr>
		<tr v-else v-for="(stakeholder, index ) in stakeholdersmonitor" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span></td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

</div>

</div>

<!--   -->
<div id="progressstatusreporttemplate" v-if="cockpit && reportlang=='en'" style="visibility:hidden; position:absolute; width:900px; top:-1500000px">

<h1>Project status report</h1>
<table style="width: 100%;">
<tbody>
	<tr>
		<td style="width: 25%;"><div style="text-align: left;">Project:</div></td>
		<td style="width: 75%;">{{ cockpit.name }}</td>
	</tr>
	<tr><td style="width: 25%;"><div style="text-align: left;">Report:</div></td>
		<td style="width: 75%;">{{ newslettertitle }} </td>
	</tr>
	<tr>
		<td style="width: 25%;"><div style="text-align: left;">Period:</div></td>
		<td style="width: 75%;">{{ timeperiodstart  }} - {{ timeperiodend  }}</td></tr>
	<tr>
		<td style="width: 25%;"><div style="text-align: left;">Author:</div></td>
		<td style="width: 75%;">{{ loggedinuser.firstname }} {{ loggedinuser.lastname }}</td>
	</tr>
	</tbody>
</table>


<br>

<p>[ Dear all, <br>Add your welcome paragraph here... ]</p>

<template v-for="(section, index ) in cockpit.sections">
<h3 :key="'section'+index" v-if="section.objectives && section.objectives.length>0"><strong>{{ section.label }}</strong></h3>

<template v-for="(objective, index ) in section.objectivesdata">
<p  :key="'objectivesection_'+index+objective._id">

<img v-if="objective.indicators && objective.indicators[ objective.indicators.length - 1] && objective.noaccess == false" :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii">
<img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess ==true">[No access]</span>



</p>
</template>

</template>

<h3 v-if="cockpit.measuresnotinsections && cockpit.sections && cockpit.measuresnotinsections.length>0 && cockpit.sections.length > 0"><strong>Not grouped</strong></h3>

<template v-for="(objective, index ) in cockpit.measuresnotinsections">
<p :key="'objectivenotsection'+index"><img v-if="objective.indicators && objective.indicators[ objective.indicators.length - 1 ] && objective.noaccess == false" :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii">
<img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess ==true">[No access]</span> </p>
	
</template>

<br>
<!-- page_break -->

<h2>Project objectives status</h2>

<p>[ Add a couple of sentences describing the overall status for the project… ]</p>

<div class="fr-img-space-wrap objectivesgraphimageen" id="objectivesgraphimageen">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'objectivesgraphimageen')" @error="imagewitherror($event, 'objectivesgraphimageen')" :src="objectivesgraphimage" style="width: 621px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Average status development since the beginning of the project</span>
</span>
</span>
</div>

<template v-for="section in cockpit.sections">

<template v-for="(objective, index ) in section.objectivesdata">

	
 <h3 :key="'oh2'+index+objective._id"><img :key="'oimg'+index" v-if="objective.indicators && objective.indicators[ objective.indicators.length - 1 ] && objective.noaccess == false " :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii"><img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess">[No access]</span></h3>

 
 <template v-if="!objective.noaccess">[ Write a short summary of the status for the objective ]</template>

<template v-if="!objective.noaccess">
	<template v-for="(indicator, indexindicator ) in objective.indicators" > 

		<p v-if="objective.measuretype == 'measure' && ( new Date(indicator.indicatordate) > new Date(timeperiodstart) && new Date(indicator.indicatordate) < new Date(timeperiodend) )" :key="indicator._id + objective._id + indexindicator"> ({{ indicator.userdetail.firstname }} {{ indicator.userdetail.lastname }} - {{ momentdate(indicator.indicatordate) }} ) <span :key="'message_'+indicator._id" v-html="indicator.message"></span>

			<br :key="'brmessage_'+indicator._id">
		</p>

	</template>
</template>


</template>



</template>


<template v-for="(objective, index ) in cockpit.measuresnotinsections">


<div v-if="cockpit.measuresnotinsections && cockpit.measuresnotinsections.length > 0" :key="'nosectionstatus' + index">
	
 <h3 :key="'oh2'+index"><img :key="'oimg'+index" v-if="objective.indicators && objective.indicators[ objective.indicators.length - 1 ] && objective.noaccess == false" :src="'https://app.flowe.io/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii"><img v-else src="https://app.flowe.io/static/img/ban.png" class="fr-fic fr-dii"> {{ objective.name }} <span v-if="objective.noaccess">[No access]</span></h3>

<template v-if="!objective.noaccess">[ Write a short summary of the status for the objective ]</template>

<template v-if="!objective.noaccess">
	<template v-for="(indicator, indexindicator ) in objective.indicators" > 

		<p v-if="objective.measuretype == 'measure' && ( new Date(indicator.indicatordate) > new Date(timeperiodstart) && new Date(indicator.indicatordate) < new Date(timeperiodend) ) " :key="indicator._id + objective._id + indexindicator"> ({{ indicator.userdetail.firstname }} {{ indicator.userdetail.lastname }} - {{ momentdate(indicator.indicatordate) }} ) <span :key="'message_'+indicator._id" v-html="indicator.message"></span>
			<br :key="'br2'+index">
		</p>

	</template>
</template>


</div>

</template>

<div v-if="cockpit.masterplan">

<br>
<!-- page_break -->

<h2>Project Masterplan</h2>

<p>[ Add a couple of sentences describing the overall status of the project progress ]</p>

<div class="fr-img-space-wrap masterplanimageen" id="masterplanimageen">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'masterplanimageen')" @error="imagewitherror($event, 'masterplanimageen')" :src="masterplanimage" style="width: 621px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Project Masterplan</span>
</span>
</span>
</div>


<br>
<h3>Milestones for the report period</h3>

<table style="width: 100%;" class="reporttable">
	<thead>
		<tr>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Track</td>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Milestone</td>
			<td style="width: 20.0000%; background-color: rgb(204, 204, 204);">Date</td>
			<td nowrap="nowrap" style="width: 20.0000%; background-color: rgb(204, 204, 204); text-align:center;">Status</td>
		</tr>
	</thead>
	<tbody>
		<template v-if="currentmilestones && currentmilestones.length>0">
		<tr v-for="(milestone, index ) in currentmilestones" :key="index" >
			
			<td style="width: 30.0000%;"><div>{{milestone.tracklabel}}</div></td>
			<td style="width: 30.0000%;">{{milestone.label}}</td>
			<td nowrap="nowrap" style="width: 20.0000%;">{{milestone.eventdate}}</td>
			<td nowrap="nowrap" style="width: 20.0000%; text-align:center;">
				<img height="12px" :src="'https://app.flowe.io/static/img/dot_'+milestone.status+'.png'" class="fr-fic fr-dii">
			</td>	
			
		</tr>
		</template>
		<template v-else>
		<tr>
			<td colspan="4"><div>No Milestones in the report period</div></td>
		</tr>
		</template>
	</tbody>
</table>

<br>

<h3>Future milestones</h3>

<table style="width: 100%;" width="100%" class="reporttable">
	<tbody>
		<tr>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Track</td>
			<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Milestone</td>
			<td style="width: 20.0000%; background-color: rgb(204, 204, 204);">Date</td>
			<td nowrap="nowrap" style="width: 20.0000%; background-color: rgb(204, 204, 204); text-align:center;">Status</td>
		</tr>
		<template v-if="futuremilestones && futuremilestones.length>0">
		<tr v-for="(milestone, index ) in futuremilestones" :key="index">
			
			<td style="width: 30.0000%;"><div>{{milestone.tracklabel}}</div></td>
			<td style="width: 30.0000%;">{{milestone.label}}</td>
			<td nowrap="nowrap" style="width: 20.0000%;">{{milestone.eventdate}}</td>
			<td nowrap="nowrap" style="width: 20.0000%; text-align:center;">
				<img height="12px" :src="'https://app.flowe.io/static/img/dot_'+milestone.status+'.png'" class="fr-fic fr-dii">
			</td>
			
		</tr>
		</template>
		<template v-else>
		<tr>
			<td colspan="4"><div>No Milestones found</div></td>
		</tr>
		</template>
	</tbody>
</table>

</div>

<div v-if="cockpit.risks">

<br>
<!-- page_break -->

<h2>Project Risk</h2>

<p>[ Add a couple of sentences describing the overall status of the project risks ]</p>


<div class="fr-img-space-wrap riskgraphimageen" id="riskgraphimageen">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 421px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'riskgraphimageen')" @error="imagewitherror($event, 'riskgraphimageen')" :src="riskgraphimage" style="width: 421px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">The project risk matrix</span>
</span>
</span>
</div>


<br>

<h3>Top 3 risk</h3>

<p>These are the risks with the highest Consequence x Probability</p>

<table style="width: 100%;" class="reporttable">
	<thead>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Risk</td>
			<td style="width: 35%; background-color: rgb(204, 204, 204);">Mitigating action</td>
			<td style="width: 20%; background-color: rgb(204, 204, 204);">Owner</td>
			<td style="width: 15%; background-color: rgb(204, 204, 204);text-align:center;">CxP Indicator</td>
		</tr>
	</thead>
	<tbody>
		<tr v-if="riskstop && riskstop.length == 0 ">
			<td colspan="5"><div>No risks added.</div></td>
		</tr>
		<tr v-else v-for="(risk, index ) in riskstop" :key="index">
			<td style="width: 30%;"><div>{{ risk.label }}</div></td>
			<td style="width: 35%;word-break: break-word;">{{ risk.mitigatingaction }}</td>
			<td style="width: 20%;" nowrap="nowrap">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
			<td style="width: 15%; text-align:center;"><img height="12px" :src="'https://app.flowe.io/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
		</tr>
	</tbody>
</table>

<br>

<h3>Top 3 Closest risks</h3>


<p>These are the 3 risk with the closest proximity date</p>


<table style="width: 100%;" class="reporttable">
	<thead>
		<tr>
			<td style="width: 25%; background-color: rgb(204, 204, 204);">Risk</td>
			<td style="width: 25%; background-color: rgb(204, 204, 204);">Mitigating action</td>
			<td style="width: 20%; background-color: rgb(204, 204, 204);">Owner</td>
			<td style="width: 15%; background-color: rgb(204, 204, 204);text-align:center;">CxP Indicator</td>
			<td style="width: 15%; background-color: rgb(204, 204, 204);">Date</td>
		</tr>
	</thead>
	<tbody>
		<tr v-if="risksclose && risksclose.length == 0 ">
			<td colspan="5"><div>No risks have proximity dates</div></td>
		</tr>
		<tr v-else v-for="(risk, index ) in risksclose" :key="index">
			<td style="width: 25%;"><div>{{ risk.label }}</div></td>
			<td style="width: 25%;word-break: break-word;">{{ risk.mitigatingaction }}</td>
			<td style="width: 20%;">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
			<td style="width: 15%;text-align:center;"><img height="12px" :src="'https://app.flowe.io/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
			<td style="width: 15%;" nowrap="nowrap">{{ risk.proximity }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Risks with no mitigating action or no proximity date</h3>

<p>The following risks either do not have a mitigation action or the date of expected occurrence has not been entered</p>

<table style="width: 100%;" class="reporttable">
	<thead>		
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);"><div>Risk</div></td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Owner</td>
			<td style="width: 20%; background-color: rgb(204, 204, 204);text-align:center;">CxP Indicator</td>
		</tr>
	</thead>
	<tbody>
		<tr v-if="risksother && risksother.length == 0 ">
			<td colspan="3"><div>No risks without mitigating actions or a proximity date.</div></td>
		</tr>
		<tr v-else v-for="(risk, index ) in risksother" :key="index">
			<td style="width: 30%;"><div>{{ risk.label }}</div></td>
			<td style="width: 40%;"><div>{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</div></td>
			<td style="width: 20%;text-align:center;"><img height="12px" :src="'https://app.flowe.io/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
		</tr>
	</tbody>
</table>

<br>
<!-- page_break -->

<h3>Risk development</h3>


<div class="fr-img-space-wrap riskchartimageen" id="riskchartimageen">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event, 'riskchartimageen')" @error="imagewitherror($event, 'riskchartimageen')" :src="riskchartimage" style="width: 621px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Project risk profile development</span>
</span>
</span>
</div>

</div>

<div v-if="cockpit.stakeholders">

<br>

<!-- page_break -->

<h2>Stakeholder management</h2>
<p>[ Add a couple of sentences describing the overall status for stakeholder management ]</p>

<div class="fr-img-space-wrap stakeholderimageen" id="stakeholderimageen">
<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 421px;" draggable="false">
<span class="fr-img-wrap">
<img @load="imageonload($event , 'stakeholderimageen')" @error="imagewitherror($event , 'stakeholderimageen')" :src="stakeholderimage" style="width: 421px;" class="fr-fic fr-dib fr-fil graphimage"><span class="fr-inner">Stakeholder map</span>
</span>
</span>
</div>

<br>

<h3>Manage closely</h3>

<table style="width: 100%;" class="reporttable">
	<thead>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
		</tr>
	</thead>
	<tbody>
		<tr v-if="stakeholdersmanage && stakeholdersmanage.length==0">
			<td colspan="3"><div>There no stakeholders in this quadrant.</div></td>
		</tr>

		
		<tr v-else v-for="(stakeholder, index ) in stakeholdersmanage" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;">
				<span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span>
			 </td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Keep informed</h3>

<table style="width: 100%;" class="reporttable">
	<thead>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
		</tr>
	</thead>
	<tbody>
		<tr v-if="stakeholdersinformed && stakeholdersinformed.length==0">
			<td colspan="3"><div>There no stakeholders in this quadrant.</div></td>
		</tr>

		<tr v-else v-for="(stakeholder, index ) in stakeholdersinformed" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}</span><br></td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Keep satisfied</h3>

<table style="width: 100%;" class="reporttable">
	<thead>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
		</tr>
	</thead>
	<tbody>
		<tr v-if="stakeholderssatisfied && stakeholderssatisfied.length==0">
			<td colspan="3"><div>There no stakeholders in this quadrant.</div></td>
		</tr>
		<tr v-else v-for="(stakeholder, index ) in stakeholderssatisfied" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span></td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

<h3>Monitor</h3>

<table style="width: 100%;" class="reporttable">
	<thead>
		<tr>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
			<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
			<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
		</tr>
	</thead>
	<tbody>
		<tr v-if="stakeholdersmonitor && stakeholdersmonitor.length==0">
			<td colspan="3"><div>There no stakeholders in this quadrant.</div></td>
		</tr>
		<tr v-else v-for="(stakeholder, index ) in stakeholdersmonitor" :key="index">
			<td style="width: 30%;"><div>{{ stakeholder.label }}</div></td>
			<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span></td>
			<td style="width: 40%;">{{ stakeholder.activities }}</td>
		</tr>
	</tbody>
</table>

<br>

</div>

</div>



	<!-- end english template -->






	<!----

		xx

		x
		x
		x
		x
		x

		--->




		<div v-if="mode=='creating'">
			<p>A report is great for giving a periodic status on progress, successes, and issues.
			</p>

			<div sizem="sm" class="base-icon-label info nav-icon asalink" @click="toggleexplainreports()">
	            <span class="iconlabelbefore overflow-string iconlabel">More info</span>
	            <div class="icon-md  forcesm">
	              <span><i v-if="!explainreports" class="fal fa-info-circle" aria-hidden="true"></i> <i v-if="explainreports" class="fas fa-info-circle" aria-hidden="true"></i>
	              </span>
	            </div>
	          </div>

			<p v-if="explainreports" class="newpadding B100-b explainreports">
			<span>To create a report choose the template that best fits your style, give the report a name, eg. ‘January update’, and select a period the report represents.
			Flowe will pull data from your project for the selected period and create a draft report with the data organized according to the template.</span>
			</p>

		</div>

		<div v-if="activelockedto.islocked && ( activelockedto.userid != profile._id ) ">This draft report is currently checked out by {{ activelockedto.user }}. Try again later, or ask them to close it.</div>
		<div v-else-if="cockpit">
		<div class="card noborder noshadow">
			<div class="card-body p-0">
				<div class="card-body p-0" v-if="drafttingnewslettertabs == false && mode == 'creating' && pagemode == 'ready'">


					<form onsubmit="return false" class="mt-2" method="get" id="newslettercreateform">						

			  			<div class="mb-3">
						    <label class="form-label">Report template</label>

						    <div id="templateselector">
						    	
								<vueselect class="statuschooser" :searchable="false" :clearable = "false" v-model="preselected" :options = "templates" :autoscroll="false">
						        <template #open-indicator="{ attributes }">
						          <span v-bind="attributes"> <i class="fas fa-sort"></i></span>
						        </template>
						        <template #option="{ id, label }">
						         <div class="vueselectitem p-1 pl-2">{{label}}</div>
						        </template>
						         <template #selected-option="{ id, label }">
						          <div class="vueselectitem p-1 pl-2">{{label}}</div>
						        </template>
						        <template #selected-option-container="{ option, deselect, multiple, disabled }">
						        <div class="vueselectitem p-1 pl-2">{{option.label}}</div>
						        </template>
						      </vueselect>


						    </div>

						    <span class="text-danger hide">name is required</span>
						  </div>

						  <div class="mb-3">
						    <label class="form-label">Report language</label>

						    <div id="languageselector">
						    	
								<vueselect class="statuschooser" :searchable="false" :clearable = "false" v-model="langpreselected" :options = "languages" :autoscroll="false">
						        <template #open-indicator="{ attributes }">
						          <span v-bind="attributes"> <i class="fas fa-sort"></i></span>
						        </template>
						        <template #option="{ id, label }">
						         <div class="vueselectitem p-1 pl-2">{{label}}</div>
						        </template>
						         <template #selected-option="{ id, label }">
						          <div class="vueselectitem p-1 pl-2">{{label}}</div>
						        </template>
						        <template #selected-option-container="{ option, deselect, multiple, disabled }">
						        <div class="vueselectitem p-1 pl-2">{{option.label}}</div>
						        </template>
						      </vueselect>


						    </div>

						    <span class="text-danger hide">name is required</span>
						  </div>


						<div class="mb-3">
						    <label class="form-label">Name</label>
						    <input type="text" id="newslettertitle"  name="title" v-model="newslettertitle" placeholder="Give the report a title" onfocus="this.placeholder = '' " onblur="this.placeholder = 'Give the report a title'" maxlength="80" class="form-control">
							
							<p class="text-danger" v-if="invalidtitle != '' && invalidtitle == 'invalid'">Title is empty</p> <p class="text-danger" v-if="invalidtitle != '' && invalidtitle == 'maxlength'">Title is more than 80 characters</p>
						  </div>


						<div class="mb-3">
						    <label class="form-label">Start date</label>
						    <flat-pickr readonly="readonly" :config="calendarConfigstart" v-model="periodStart" name='from' 
									id="newsletterdatefrom" placeholder="Period from" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Period from'" class="form-control" type="text"></flat-pickr>
									<p class="text-danger" v-if="invalidfrom == 'invalid'">Invalid date</p>
						  </div>

						 <div class="mb-3">
						    <label class="form-label">End date</label>
						    <flat-pickr readonly="readonly" :config="calendarConfigend" v-model="periodend" 
									id="newsletterdateto" placeholder="Period to" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Period from'"  class="form-control" type="text" name="to"></flat-pickr>

									<p class="text-danger" v-if="invalidto == 'invalid'">Invalid date</p>
						  </div>

			  			
						<div class="row">
							<div class="col text-right">

								<div style="visibility: hidden; position: absolute; width: 900px;">
								<Boardcharts :wide="500" :size="200" @ondumppng="dumppng" ></Boardcharts>
								<Riskcharts :showall="true" :wide="500" :size="200" @ondumppng="dumpriskpng"></Riskcharts>
								</div>

								<button v-if="1==2" type="button" @click="renderexternalimages()">Images</button>
								
								
								<button v-if="!isworking" type="button" :disabled = " newslettertitle == '' || periodend == null || periodStart == null || imagesready == false " @click.prevent="precreatedraft(); " class="btn btn-primary" aria-selected="false">Import data and create report</button>

								<button v-if="isworking" class="btn btn-primary" disabled="disabled"><i class="fa fa-circle-o-notch fa-spin"/> Importing data and preparing report</button>

							</div>
						</div>
					</form>
				</div>
				<!-- Nav tabs -->

				<!-- <div class = "newslettersmall">
					<span class = "mx-auto "> you don't have access to draftting newsletters on this type of devise </span>
				</div> -->

<div class="d-flex">
	<div class="flex-grow-1">
	<div id="newslettereditorstage" class="mr-2 " style=" min-width: 360px;">

	<div class="d-flex">	  
		<div class="p-2">
			<button @click="doneedit(true)" type="button" class="btn btn-secondary" ><i class="fas fa-arrow-left"></i>&nbsp;Back</button>
		</div>
		<div class="p-2 flex-grow-1" v-if="!newsletter.name">
			<b-skeleton width="65%"></b-skeleton> 
		</div>
		<div class="p-2 flex-grow-1" v-else>
			<h3> {{newsletter.name}} </h3> 
		</div>
		<div class="p-2 align-self-center">

			<div class="small saving" v-if="savingnewsletter=='saving'">
				Saving<i class="fa fa-circle-o-notch fa-spin"></i>
			</div>
			<div class="small saving" v-if="savingnewsletter=='saved'">
				Saved
			</div>
			<div class="small red" v-if="savingnewsletter=='notsaved'">
				Not saved
			</div>

		</div>
		<div class="p-2"> 

			<a :disabled="!boardisactive" @click.stop.prevent="publishmodal()" type="button" tabindex="-1" role="button" class="btn btn-primary">
				Publish</a>
		</div>
	</div>

	<p v-if="errors && errors.length > 0">{{ errors }}</p>
	<div class="card3" id="newslettereditorcard" style="position: relative;">
		<froala id="newslettereditor" :tag="'textarea'" :config="froalaconfig" v-model="newsletter.contents" class="mb-0" :onManualControllerReady="initializefroala"></froala>
	</div>

	</div>



	</div>

	<div class="card44" :class="{'sidebarright':sidebarexpanded, 'sidebarmini2':!sidebarexpanded}" style="height: 100px;">&nbsp;</div>
  
</div>

                  </div>

              </div>

          </div>

            
          </div>

          <div v-if ="errorloading" >
			<b-popover
				target="errormessage"
				custom-class= "danger"
				placement="right"
				ref="popover"
				id = "errormessagepopover" 
				@show="onpopovershow"
				title=" Problem saving"
				:show.sync="errorloadingpopover">

			<p> We are encountering a problem savings your changes. This may be due to a connection error or a fault on the server. Save your content offline and try to refresh the page.</p>
				
			</b-popover>
		</div>

		

      </div>

      <div id="rightsidebar" class="card44 border-left sticky-top"  :class="{ 'sticky-top-zindex':bodyscrolldirection==true,  'sidebarright':( canexpand && sidebarexpanded )}" style="max-width: 340px; height: 100vh; background-color: #fff; z-index: 10px;" @scroll="divscrolling()">
  	
  	<div class="d-flex flex-column flex-shrink-0 bg-body-tertiary" :class="{'sidebarmini':( !canexpand || !sidebarexpanded )}">

    <ul class="nav nav-pills nav-flush mb-auto text-center mt-2" :class="{ 'expanded':sidebarexpanded, 'flex-column':( !canexpand || !sidebarexpanded )}">
      <li class="apron-item" v-if="!sidebarexpanded">
        <a href="#" class="nav-link py-2 rounded-0 sidebaritems" :class="{ }" aria-current="page" title="Expand" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="expandsidebar(true)">
          <i class="far fa-arrow-to-left"></i>
        </a>
      </li>
      <li class="apron-item" v-if="sidebarexpanded">
        <a href="#" class="nav-link py-2 rounded-0 sidebaritems" :class="{}" aria-current="page" title="Expand" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="expandsidebar(false)">
          <i class="far fa-arrow-to-right"></i>
        </a>
      </li>
      <li class="apron-item" :class="{ 'border-2':activetab == 1 && sidebarexpanded}">
        <a href="#" class="nav-link py-2 rounded-0 sidebaritems"  title="Summary generator" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="opentab(1)">
          <i v-if="activetab == 1" class="fas fa-magic"></i>
          <i v-else class="fal fa-magic"></i>
        </a>
      </li>
      <li class="apron-item" :class="{ 'border-2':activetab == 2 && sidebarexpanded}">
        <a href="#" class="nav-link py-2 rounded-0 sidebaritems"  title="Project objectives" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="opentab(2)">
          <i v-if="activetab == 2" class="fas fa-dot-circle"></i>
          <i v-else class="fal fa-dot-circle"></i>
        </a>
      </li>
      <li v-if="1==3" class="apron-item" :class="{ 'border-2':activetab == 3 && sidebarexpanded}">
        <a href="#" class="nav-link py-2 rounded-0 sidebaritems"  title="Publish report" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="opentab(3)">
          <i v-if="activetab == 3" class="fas fa-share-alt"></i>
          <i v-else class="fal fa-share-alt"></i>
        </a>
      </li>
      <li class="apron-item" :class="{ 'border-2':activetab == 4 && sidebarexpanded}">
        <a href="#" class="nav-link py-2 rounded-0 sidebaritems"  title="Export report" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="opentab(4)">
          <i v-if="activetab == 4" class="fas fa-file-download"></i>
          <i v-else class="fal fa-file-download"></i>
        </a>
      </li>
    </ul>
  </div>


	<div v-show="activetab==1 && sidebarexpanded">
	<aipanelinline @onresetcompletions="resetcompletions" :lang="newsletter.language ? newsletter.language : 'en'" :maxHeight="windowHeight" :completions="newsletter.completions" :newsletterid="newsletterid" :timeperiodstart="timeFormat(newsletter.periodstart)" :timeperiodend="timeFormat(newsletter.periodend)" :key="aipanelupdate"></aipanelinline>
	</div>


	<div id="newslettersidebarwrapperscroll" :style="'max-height: '+windowHeight2+'px; overflow-y: scroll; overflow-x: hidden;'" class="newslettersidebarwrapperscroll p-2 border-top" v-show="activetab==2 && sidebarexpanded">

	<div class="p-2" >
		<div class="h5 text-center" style="font-size: 18px;">Project objectives</div>

		<div>Look up status and comments for all the objectives attached to this project.</div>
	</div>

		<b-skeleton-wrapper v-if="!cockpit._id">
	      <b-card class="mt-2">
	        <b-skeleton width="85%"></b-skeleton>
	        <b-skeleton width="35%"></b-skeleton>
	        <b-skeleton width="95%"></b-skeleton>
	      </b-card>

	      <b-card class="mt-2">
	        <b-skeleton width="85%"></b-skeleton>
	        <b-skeleton width="35%"></b-skeleton>
	        <b-skeleton width="95%"></b-skeleton>
	      </b-card>

	      <b-card class="mt-2">
	        <b-skeleton width="85%"></b-skeleton>
	        <b-skeleton width="35%"></b-skeleton>
	        <b-skeleton width="95%"></b-skeleton>
	      </b-card>

	      <b-card class="mt-2">
	        <b-skeleton width="85%"></b-skeleton>
	        <b-skeleton width="35%"></b-skeleton>
	        <b-skeleton width="95%"></b-skeleton>
	      </b-card>

	      <b-card class="mt-2">
	        <b-skeleton width="85%"></b-skeleton>
	        <b-skeleton width="35%"></b-skeleton>
	        <b-skeleton width="95%"></b-skeleton>
	      </b-card>
		</b-skeleton-wrapper>

		<div v-if="cockpit.sections" id="newslettersidebar" class="" v-bind:class="{ 'hide': cockpit.myrole != 'owner' && cockpit.myrole != 'contributor'}" style="margin-bottom: 10px;">											

			<template v-for="(section, index ) in cockpit.sections" >

				<h4 v-if="section.objectivesdata" :class ="{'pb-1 mb-0': !index, 'my-1':index}" style="width: 100%;" :key="section._id"><span style="background-color: #ffffff; padding-right: 20px;" v-text="section.label"></span></h4>

					<template v-for="objective in section.objectivesdata">

					<objectivesCard @onshowobjectivesdescription="showobjectivedescriptionmodal" :requestAccess="false" :activeCockpit="cockpit.isactive" ref="sideobjectivedata1"  :key="objective._id" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" :usemenu="false" :useswipe="false" :fulldesc="false" :scope="'newsletter'" :feedback="'readonly'" :perpage="8" ></objectivesCard>

					</template>
			</template>

			<h4 v-if="cockpit.sections && cockpit.sections.length" style="width: 100%;" class = "my-1 "><span style="background-color: #ffffff; padding-right: 20px;"> Not grouped</span><p class="border-top" style="margin-top: -9px;">&nbsp;</p></h4>



			<template v-for="objective in cockpit.measuresnotinsections">
			
			<objectivesCard @onshowobjectivesdescription="showobjectivedescriptionmodal" :requestAccess="false" :activeCockpit="cockpit.isactive" ref="sideobjectivedata2"  :key="objective._id" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" :usemenu="false" :useswipe="false" :fulldesc="false" :scope="'newsletter'" :feedback="'readonly'" :perpage="8" ></objectivesCard>

			</template>

			

		</div>
	</div>


	<div v-show="activetab==3 && sidebarexpanded" class="border-top">
		<div class="p-2 text-left">

						<div v-bind:class="{'hide':page!='stillloading'}">
							<i class="fa fa-circle-o-notch fa-spin"></i>
						</div>
						<div class="noshadow" id="newslettersteps" v-bind:class="{'hide': ( page=='stillloading' || publishconfirmation == true )  }">

							<div class="p-2" >
								<div class="h5 text-center mb-1" style="font-size: 18px;">Publish report</div>

								<div class="mb-2">{{ newsletter.name }} <br>({{ newsletter.periodstart | timeFormat }} - {{ newsletter.periodend | timeFormat }}) </div>

								<div class="mb-2" >When you publish this report it will be made available online and all user with access to this project will be able to see it. It is not possible to edit the contents of a report once it has been published.</div>

								<b-form-checkbox
							      id="sendnotification-1"
							      v-model="sendnotification"
							      name="sendnotification-1"
							      value="true"
							      unchecked-value="false"
							    >
							      Send an email notification to all users with access to the project.
							    </b-form-checkbox>

								<div class = "mt-3"> 
									<button v-on:click="dopublish( newsletter._id ); " class="btn btn-primary">Publish report</button>
								</div>

								


							</div>
						</div>

						<div id="newsletterpublishedconfirmation" class="card-body" v-bind:class="{'hide': ( page=='stillloading' || publishconfirmation == false )  }">
							<div class="noshadow">
								<div class="text-center" >
									<h3 style = "text-align: center !important">Success!</h3>

									{{ newsletter.name }} ({{ newsletter.periodstart | timeFormat }} - {{ newsletter.periodend | timeFormat }})

									<p v-if="1==2" class="mt-3">You can click on the report title to read the report and check any feedback that's coming in.</p>
                                      <!-- //#newsletter_5cae71665d927f2e84dea33e
                                      	<p class="mt-3 mb-3"><button type="button" class="btn btn-primary" onclick="gotonewspublishedletter()">Take me to the newsletter</button></p> -->
                                      </div>
                                  </div>
                              </div>

                          </div>
	</div>
	<div v-show="activetab==4 && sidebarexpanded" class="border-top">
		<div class="p-2 pt-3">

			<div class="h5 text-center" style="font-size: 18px;">Export report</div>

								<div>Select the format to export this report.</div>
			
			

			<b-dropdown id="dropdown-1" :text="exportformat" class="m-md-2">
		    	<b-dropdown-item @click.stop.prevent="exportformat='Word'">Word</b-dropdown-item>
		  	</b-dropdown>


		  	<button class="btn btn-primary" @click.stop.prevent="exporttoword()">Export</button>

		</div>
	</div>

  </div>

  </div>
  </template>

  <style type="text/css">

  	.saving{
  		color: var(--G500);
  	}

  	#newslettereditorcard .fr-wrapper{
  		max-width: 760px !important;
  		margin: auto;
  	}

  	#newslettereditorcard .fr-wrapper.wide{
  		max-width: 1800px !important;
  		margin: auto;
  	}

  	#newslettereditorcard .fr-wrapper, #newslettereditorcard .fr-toolbar, #newslettereditorcard .fr-second-toolbar{
  		border: none !important;
  	}

  	.apron-item{
  		width: 60px;
  		height: 48px;
  	}

  	#rightsidebar{
  		top: 72px;
  		position: fixed;
  		right: 0px;
  	}


    /*
    padding: 50px 2%;
    padding-bottom: 50px;
    padding-bottom: 65px;
    border-radius: 30px;
    border: .5px solid $N500;
    background-color: #FFF;
    width: 40%;
    box-shadow: inset 0px 0px 0px -4px rgba(255,255,255,0.1),1px 1px 6px rgba(0,0,0,0.05),1px 1px 8px   rgba(0,0,0,0.07);
    margin: auto;
    position: relative;*/



  	
  	.phoneBox {
		border-width: 4px;
		border-style: solid;
		border-color: #000;
		min-width: 375px !important;
		max-width: 375px !important;
		max-height: 667px !important;
		overflow: scroll;
		border-radius: 20px;
		margin-right: auto;
		margin-left: auto;
		position: relative;
	}

	.phoneBoxisland{
	    content: '';
	    position: sticky;
	    top: 0;
	    left: 50%;
	    transform: translateX(-50%);
	    width: 32%;
	    height: 10px;
	    background-color: #000;
	    border-radius: 0 0 4px 4px;
	    z-index: 99999;
	}

	.b-table-sticky-header{

		  scrollbar-width: thin;          /* "auto" or "thin" */
	  scrollbar-color: var(--N200);	
	}

  </style>

  <style lang = "scss" scoped>

  	/*body{
  		cursor: progress ;
  	}
*/

	.sidebaritems{
		font-size: 16px;
	}

	.nav-pills.expanded>li+li {
	  margin-left: 25px;
	} 

	.border-2 {
	  border-bottom: 2px solid var(--B500) !important;
	}


  	.saveactivity{
  		background-color: var(--N200);
  		width: 70px;
  		text-align: center;
  		position: absolute;

  		top: 50px;
  		left: 10px;
  		z-index: 999999;
  		
  	}


  	.sticky-top-zindex{
  		z-index: 10 !important;
  	}

  	.fr-button-active{
  		background-color: var(--N200) !important;
  	}

  	body, #phonescreen, #newslettersidebarwrapperscroll {
	  scrollbar-width: thin;          /* "auto" or "thin" */
	  scrollbar-color: var(--N200);
	}

	html::-webkit-scrollbar-track, #phonescreen::-webkit-scrollbar-track
	{
		background-color: #FFFFFF !important;
	}

	html::-webkit-scrollbar, #phonescreen::-webkit-scrollbar
	{
		width: 10px;
		background-color: #F5F5F5 !important;
	}

	html::-webkit-scrollbar-thumb, #phonescreen::-webkit-scrollbar-thumb
	{
		background-color: #c9c8c8 !important;
	}
	html::-webkit-scrollbar-thumb:hover, #phonescreen::-webkit-scrollbar-thumb:hover
	{
		background-color: #b3b2b2 !important;
	}

  	.sidebarmini{
  		width: 60px !important;
  		min-width: 60px !important;
  		max-width: 60px !important;
  	}

  	.sidebarmini2{
  		width: 40px !important;
  		min-width: 40px !important;
  		max-width: 40px !important;
  	}

  	.sidebarright{
  		/*width: 42.5em;*/
  		width: 340px;
  		max-width: 340px;
  		min-width: 340px;
  	}

  	.mouse{
  		cursor: pointer;
  	}

  	.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
	  font-weight: 700;
	  color: var(--bs-nav-underline-link-active-color);
	  border-bottom: solid 2px #000;
	}

  	.myactionbutton{
		position:fixed;
		width:45px;
		height:45px;
		bottom:40px;
		right:40px;
		background-color:var(--B700);
		color:#FFF;
		border-radius:50px;
		text-align:center;
	}

  	.vs--open .vs__open-indicator {
	  transform: none !important;
	}

	#templateselector .vs__actions {
	  display: -webkit-box !important;
	  display: -ms-flexbox !important;
	  display: flex !important;
	  padding: 0 13px !important;
	}

	#templateselector .vs__dropdown-menu{
		/*top: 23px !important;*/
	}

  .vueselectitem{
  	/*height: 32px;*/
  	/*line-height: 32px;*/
  	padding-left: 10px;
  }

  .explainreports{
  	color: var(--B700);
  }
 
  .iconbox:hover {
  	text-decoration: underline !important;
  }
  .noborder{
  	border: 0 !important;
  }
  .noshadow{
  	box-shadow: none !important;
  }
  .cardwrapper {
    height: auto;
    margin-bottom:8px;
}

#newslettersidebar{
	border:none;
}

#newslettereditorcard{
	/* height:100%; */
	.fr-wrapper{
		/*// max-height:100%!important;*/
		height: 100%;
	}
}
/*#newslettermodalid{
	.modal-body{
		overflow-y: hidden;
	}
}*/
/*
#newslettermodalid___BV_modal_body_{
	padding: 0 !important;
}*/

.fr-toolbar::after {
  clear: both;
  display: none;
  content: "";
  height: 0;
}

 #newslettermodalid{
  	#btn-radios{
  		label{
  			width: 150px
  		}

  		.active{
  			i{font-weight: 700}
  		}
  	}
}
</style>
<script>
	import Cockpitsapi from '@/services/api/cockpits'
	import Newslettersapi from '@/services/api/newsletters'
	import Userapi from '@/services/api/users'

	import objectivesCard from '@/views/cockpit/components/objectivesCard'
	import moment from 'moment';
	import Boardcharts from '@/components/boardcharts/boardcharts.vue'
	import Riskcharts from '@/components/boardcharts/riskcharts.vue'

	 import vueselect from '@/views/tools/vueselect'
	
	import aipanel from '@/components/aipanel'
	import aipanelinline from '@/components/aipanel/inline.vue'

	import cockpitdescription from '@/views/cockpit/components/cockpitdescription'

	import {bus} from '@/main'

	import AWS from 'aws-sdk'
	import axios from 'axios'

  const ID = process.env.VUE_APP_CLIENT;
  const SECRET = process.env.VUE_APP_CRET;

	// The name of the bucket that you have created
	const BUCKET_NAME = 'floweimage';
	AWS.config.update({region: 'eu-west-3'});

	const s3Bucket = new AWS.S3({
	accessKeyId: ID,
	secretAccessKey: SECRET,
	apiVersion: '2006-03-01', 
	region: 'eu-west-3',
	params: {Bucket: BUCKET_NAME,ACL : 'public-read',}
	});

	export default{
		name:'reportseditpage',
		data(){
			let self = this
			return{
			savedtime:"",
			froalaupdate:0,
			publisheddone:false,
			aipanelupdate:0,
			slimscrolloptions:{
                height:this.windowHeight2+'px',
                size:10,
                railVisible: true,
				alwaysVisible: false,
				railOpacity: 0.3,
				disableFadeOut :false
            },
			bodyscrolldirection:0,
			bodylastscrollposition:0,
			bodyscrollposition:0,
			windowinnerHeight:0,
			stagesizestate:0,
			exportformat:"Word",
			sidebarexpanded:true,
			linkInitControls:null,
			cockpitname:"",
			previewmodalshow:false,
			publishmodalshow:false,
				activetab:1,
				canexport:true,
			showaipanel : false,
			langpreselected: { id:null, label: 'Select language' },
			languages:[ { "code": "en", "name": "English" }, { "code": "da", "name": "Danish" }, { "code": "de", "name": "German" }, { "code": "fr", "name": "French" } ],

			preselected: { id:null, label: 'Select template' },
			templates : [ { id:"team1", label:"Team report template" } , { id:"projectstatusreporten", label:"Project status report (EN)" }, { id:"projectstatusreportdk", label:"Project status report (DK)" } , { id:"blank", label:"Blank template"}  ],
			modalupdate:0,
			modalsize:"md",
			explainreports:false,
			isworking:false,
			imgdims:{},
			mode:"editor",
			generatedlinkfilename:"",
			exportedwordlocation:"",
			exportingtoword:false,
			reportlang : "en",
			waitingforimages:false,
			pngdump:"",
			riskpngdump:"",
			loadedimages:0,
			objectivesgraphimage:"",
			masterplanimage:"",
			stakeholderimage:"",
			riskchartimage:"img.png",
			riskgraphimage:"",
			timeperiodend: null,
			timeperiodstart: null,
			sendnotification: true,
			riskstop:[],
			risksclose:[],
			risksother:[],
			currentmilestones:[],
			futuremilestones:[],
			stakeholdersmanage:[], 
			stakeholdersinformed:[], 
			stakeholderssatisfied:[], 
			stakeholdersmonitor:[],
			cockpit:{},
			draftnewsletters : [],
			publishednewsletters : [],
			isDraftingNewsletter:false,
			periodStart:null,
			periodend:null,
			newslettertitle:'',
			bodytext:'',
			isshown: false,
			froalaconfig: {},
			draftstable : null,
			publishedtable : null,
			items: [],          
			errors: [],
			invalidfrom : "empty",
			invalidto : "empty",
			invalidtitle : "empty",
			activelockedto : {},
			creatingnewsletter : false,
			newsletter : {},    
			datefrom : new Date(),
			dateto : new Date(),
			datefromtext : "",
			datetotext : "",
			currentbodytext : "",
			newsletterviewerdata : "",
			commited : true,
			editor : null,     
			status : "idle",
			viewportheight: 0,
			viewportwidth: 0,
			editing : false,
			haschanges : false,
			maxstep : 1,
			page : 'overview',
			preview : 'desktop',
			commentposting : '',
			activecomment : {},
			publishconfirmation : false,
			editorcontent : "",
			devisetype: '',
			pagemode:'notready',
			activenewsletter:{},
			showdeleteactivenewsletter:false,
			calendarConfigstart: {maxDate:'', minDate:null, altFormat: 'j M, Y', altInput: true, dateFormat: 'Y-m-d', showMonths:1, monthSelectorType:'static'},
			calendarConfigend: {maxDate:'', minDate:null, altFormat: 'j M, Y', altInput: true, dateFormat: 'Y-m-d', showMonths:1, monthSelectorType:'static'},
			drafttingnewslettertabs:false,
			activeCockpit : {},
			indicator : null,
			comments :[],
			savingnewsletter: '',
			newsletterislocked : false,
			newsletterid : "",
			size:"",
			profile:{},
			errorloading:false,
			errorloadingpopover:false,
			cockpitdescriptionmodalshown:false,
			radionoptions: [
	          { html: '<i class="fal fa-desktop"></i> Desktop', value: 'desktop' },
	          { html: ' <i class="fal fa-mobile-android-alt"></i> Mobile', value: 'mobile' },
	        ]
     
		}
	},	
	props:{
		shown:{
			type:Boolean,
			default:()=>false
		},
		viewer:{
			type:Boolean,
			default:()=>false
		}		
	},
	components:{objectivesCard,Boardcharts,Riskcharts,vueselect,aipanel,aipanelinline,cockpitdescription},
	computed:{

		boardisactive(){
			return this.$store.getters.GetCockpit.isactive
		},


		windowHeight2(){

			let hh = this.windowinnerHeight - 140
			return hh + ""

		},

		windowHeight(){

			let hh = this.windowinnerHeight - 100;
			return hh + ""

		},

		canexpand(){

			return true
		},

		template(){

			return this.preselected.id == null ? "team1" : this.preselected.id
		},

		imagesready(){

			let toreturn = true


			if( ( this.template == "projectstatusreporten" || this.template == "projectstatusreportdk" ) && this.waitingforimages == true ) {
				toreturn = this.loadedimages == 5 ? true : false


			}

			return toreturn 

		},
		
		cockpitid(){
        		return this.$route.params.cid
     		},

		token(){
			return this.$store.getters.token
		},
		
		orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

		loggedinuser(){
			return this.$store.getters.user
		}
	},
	/*beforeRouteLeave (to, from, next) {
     
      alert('vxzczxczx')
       console.log( to, from, next, "beforeLeaveRoute PageOne");
        next()
    },*/
	mounted(){

		this.savedtime = new Date()


		this.preselected = { id:null, label: 'Select template' }
		this.isworking = false

		this.exportedwordlocation = ""
		this.exportingtoword = false

		document.nmodal = this

		var self = this	


		this.profile = this.$store.state.user.userInfo

		//alert('')

		

		/*window.onbeforeunload = function (event) {
			
			Newslettersapi.Unlock( self.newsletterid, self.token)
		}*/

		this.getWindowWidth()
		this.$nextTick(function() {
			window.addEventListener("resize", this.getWindowWidth);
			/*window.addEventListener("beforeunload", function(){

			alert('xxvv')
		});*/
              //window.addEventListener(‘resize’, this.getWindowHeight);
          })

		
		this.froalaconfig = this.$store.state.config

		//this.froalaconfig.toolbarInline = true
		
		this.froalaconfig.toolbarSticky =  true
		this.froalaconfig.toolbarStickyOffset = 0
		
		//this.froalaconfig.zIndex = 9999
		
		this.froalaconfig.heightMin = "70dvh"
		//this.froalaconfig.heightMax = "70dvh"
		this.froalaconfig.heightMax = 0

		let timeout = null 

		this.froalaconfig.events.contentChanged = function(){ 

			if( timeout ){
				clearTimeout(timeout)
			}

			timeout = setTimeout( function(){

				self.updatedraft(self) 

			}, 5000)

			

		}

		//this.froalaconfig.heightMax = "65vh"

		//, 'fullscreen', 'print', 'html'
		let toolbar = ['undo', 'redo' , 'reportPreview' , 'reportDelete' , 'reportExpand' ]


		this.froalaconfig.toolbarButtons.moreMisc.buttons = toolbar
		this.froalaconfig.toolbarButtons.moreMisc.buttonsVisible = 10
		//console.log(  ,"cfg")	
		
		$.getJSON( "https://s3token2.flowe.io/", function(data){
			self.froalaconfig.imageUploadToS3 = data
			self.froalaconfig.fileUploadToS3 = data      
		})

		//alert( flatpickr.formatDate(new Date(), "m-d-Y") )
		//bus.$on('opennewslettermodal', this.opennewslettermodal )

		//alert()

		if( this.$route.query.openexisting ){
			
			let forceopen = false

			if( this.$route.query.forceopen ){
				forceopen = this.$route.query.forceopen
			}

			this.opennewsletterforediting( this.$route.query.nid , forceopen )
		}else{
			this.opennewsletterforcreating()
		}
		//	alert('x')
		//	bus.$on('opennewsletterforeditingmodal', this.opennewsletterforediting)
		//}else{
		//	alert('y')
		//	bus.$on('onopennewsletterforcreating', this.opennewsletterforcreating)
		//}	
		

			
		$('html').off('click',function() {
			self.errorloadingpopover = false
		});							
		$('html').on('click',function() {
			self.errorloadingpopover = false
		});


		setTimeout( function(){

			//$('#newslettersidebar')

			//new MiniBar('#newslettersidebar');

			$(".tooltip").tooltip("hide");
			self.initpage()
		
		}, 200)

		

		},

		watch:{

			boardisactive:function( value ){

				if( value == false ){
					this.doneedit(true)
				}
			},

			'newsletter.contents':function(e,v){

				this.newsletterviewerdata = e

			},

			loadedimages: function( e ){

				let self = this

				if( e == 5 && this.waitingforimages == true ){					

					self.waitingforimages = false
					
					setTimeout( function(){

						self.createdraft()
						
					}, 2000)
					
				}
			},

			imagesready : function( e, v ){

				//alert( e )

				if( v == false && e == true ){

					this.isworking = true

				}
			},

			shown : function( e ){
				this.isshown = e
			},

			periodStart : function(e){
				this.calendarConfigend.minDate = e

				this.timeperiodstart = e
				this.rendercurrentmilestones()
				this.renderfuturemilestones()
			},

			periodend : function(e){
				this.calendarConfigstart.maxDate = e

				//this.timeperiodstart = from//.getTime()
				this.timeperiodend = e
				this.rendercurrentmilestones()
				this.renderfuturemilestones()
			},

			mode : function(e){
				console.log(e)
			},

			template : function(e){

				if( e == "projectstatusreporten"){
					this.reportlang = "en"
				}

				if( e == "projectstatusreportdk"){
					this.reportlang = "dk"
				}

				this.loadedimages = 0

				//if( e == 'projectstatusreport' ){
				//	this.renderexternalimages()
				//}
				//console.log(e)
			}
		},

		filters: {

			
		},

		created() {

			window.addEventListener("scroll", this.divscrolling);

		  this.windowinnerHeight = window.innerHeight
		  window.addEventListener("resize", this.windowOnresize);
		},
		destroyed() {
		  window.removeEventListener("resize", this.windowOnresize);
		},

		methods:{

			onmodaldescriptionhidden(){
				this.cockpitdescriptionmodalshown = false
			},


			showobjectivedescriptionmodal( objective ){

				let self = this

				let activeobjective = objective

				bus.$emit('onopendescription', activeobjective, self.loggedinuser, self.token, 'Viewer', 'objective', false, activeobjective.isactive)

				this.$nextTick(function(){
					self.cockpitdescriptionmodalshown = true
				})

	        },


			timeago( datestr ){

				return moment(datestr).fromNow()


			},

			onpublisheddone( isdone ){

				if( this.publisheddone ){

					this.updatedraft()
					this.doneedit(false)
				}

				

				//this.opennewsletterforediting( this.$route.query.nid )
			},

			resetcompletions(){

				this.newsletter.completions = null
				this.aipanelupdate++
			},

			divscrolling( i  ){

				let self = this

				this.$nextTick(function(){
						self.bodyscrollposition = window.pageYOffset

						self.bodyscrolldirection = self.bodylastscrollposition > self.bodyscrollposition

						self.bodylastscrollposition = self.bodyscrollposition
					})

				
			},

			windowOnresize(){

				let self = this

				this.$nextTick(function(){
						self.windowinnerHeight = window.innerHeight
					})

				
			},

			compresseditorview(){

				let stage = document.getElementById('newslettereditorstage')
				//stage.style.width = "900px";
				///***stage.style.maxWidth = "900px";
				this.stagesizestate = 0

			},

			expandsidebar(isopen){

				let expandlater = false

				if(!this.sidebarexpanded){					

					if( this.stagesizestate > 0 ){
						expandlater = true
					}

					//this.compresseditorview()
					
				}

				let self = this

				this.sidebarexpanded = isopen

				if( expandlater ){
					this.$nextTick(function(){
						//self.expandeditorview()
					})
				}
				
			},

			expandeditorview( isreset ){

				this.stagesizestate = this.stagesizestate ? 0 : 1
				
				let stage = document.getElementById('newslettereditorstage')

				let editor = stage.querySelector('.fr-wrapper')

				if( this.stagesizestate == 1 ){
					editor.classList.add('wide')
				}else{
					editor.classList.remove('wide')
				}

			},
			expandeditorview2( isreset ){

				let el = document.querySelectorAll('[data-cmd="reportExpand"]');

				if( this.stagesizestate == 1 ){

					let stage = document.getElementById('newslettereditorstage')
					//stage.style.width = "900px";
					///***stage.style.maxWidth = "900px";
					this.stagesizestate = 0

					if( el[0] ){
						el[0].classList.remove("fr-button-active");
					}
					//this.compresseditorview()
					return
				}

				/*let box = document.getElementById('newslettermodalid')
				let stage = document.getElementById('newslettereditorstage')
				let rightside = this.sidebarexpanded ? 350 : 35

				let newsize = box.offsetWidth - rightside
				//alert(box.offsetWidth - rightside.offsetWidth)
				//alert( newsize )
				stage.style.width = newsize + "px";
				stage.style.maxWidth = newsize + "px";*/

				this.stagesizestate = 1

				let stage = document.getElementById('newslettereditorstage')


				//stage.style.width = "900px";
				///***stage.style.maxWidth = '99999px'


				

				if( el[0] ){
					el[0].classList.add("fr-button-active");
				}

				 


				
			},

			prepunload(){
				Newslettersapi.Unlock( this.newsletterid, this.token)
			},

			opentab(index){		

				let self = this

				if( !this.sidebarexpanded ){
					this.expandsidebar(true)
				}
				
				//this.sidebarexpanded = true
				this.activetab = index


				this.$nextTick(function(){
					if( index == 2 ){
						self.getobjectives()
					}
				})
				

				

				/*setTimeout( function(){
					
				}, 50)	*/			

			},
			doneedit( tounlock ){

				let self = this

				this.updatedraft(this) 

				if( tounlock ){
					Newslettersapi.Unlock( this.newsletterid, this.token)
				}

				setTimeout( function(){

					var tourl = "/" + self.orgkey + "/projects/" + self.cockpitid + "/reports"
							self.$router.push( tourl )

				},200)				

				
			},

			publishmodalshown(){

			},
			publishmodalhidden(){

				if( this.publishconfirmation == true ){

					this.doneedit(true);
				}

			},
			publishmodal(){

				$(".tooltip").tooltip("hide");
				this.page = 'publish'
				this.maxstep = 4
				this.publishmodalshow = true


			},


			previewmodalshown(){

			},
			previewmodalhidden(){

			},
			previewmodal(){

				$(".tooltip").tooltip("hide");
				this.page = 'preview'
				this.maxstep = 4
				this.previewmodalshow = true

			},


			chatGPT(){

				this.showaipanel = true
			},

			toggleexplainreports(){

				this.explainreports = this.explainreports ? false : true
			},

			wordfilename( title ) {

			    var d = new Date(),
			        month = '' + (d.getMonth() + 1),
			        day = '' + d.getDate(),
			        year = d.getFullYear();

			    if (month.length < 2) 
			        month = '0' + month;
			    if (day.length < 2) 
			        day = '0' + day;

			    return [year, month, day].join('')+' '+title+'.docx';
			},

			modalexportingmodalshown(){

				this.exportedwordlocation = ""
			},

			modalexportingmodalhidden(){

				//alert( this.exportedwordlocation )

				/*let self = this

				setTimeout( function(){

					var link = document.createElement('a');
				    link.download = 'xxxxxxxxxxxxxxxxxx.docx';
				    link.href = self.exportedwordlocation
				    link.click();

					//location.href = self.exportedwordlocation

					self.exportedwordlocation = ""

				}, 500)		*/		

				//this.exportedwordlocation = ""
			},

			imageonload( element , id ){

				/*let img = element.target

				this.imgdims[id] = { ww:img.naturalWidth, hh:img.naturalHeight } 

				console.log( this.imgdims )*/
			},

			imagewitherror( element , id ){

				if( this.waitingforimages == true ){


					/*const newDiv = document.createElement("div");
					const newContent = document.createTextNode("This image did not load properly");

					const newImg = document.createElement("img");
					newImg.src = 'https://app.flowe.io/static/img/imgban.png'
					newDiv.appendChild(newImg);
					newDiv.appendChild(newContent);


					//element.target.parentNode.insertBefore(newDiv, element.target.nextSibling);
					//element.target.remove()

					console.log( element.target.parentElement?.parentElement?.parentElement?.remove() , "dddddddddds")

					element.target.parentNode.replaceChild(newDiv, element.target);*/

					//id

					let elem = document.getElementById(id);

					if( elem ){

						let err = ''

						if( id == 'riskchartimageen' || id == 'riskchartimagedk' ){
							err = 'Unable to load the Risk profile graph, This could be because there is not enough risk data available'
						}

						if( id == 'riskgraphimageen' || id == 'riskgraphimagedk' ){
							err = 'Unable to load the Project Risk Matrix graph, This could be because there is not enough risk data available'
						}

						if( id == 'masterplanimageen' || id == 'masterplanimagedk' ){
							err = 'Unable to load the Masterplan chart, This could be because there is not enough track data available'
						}	

						
						//e = 'Unable to load the Project Risk Matrix graph, This could be because there is not enough risk data available'

						elem.innerHTML = `<table class="" style="width: 100%;"><tbody><tr><td style="width: 100%; background-color: rgb(209, 213, 216);">${err}<br></td></tr></tbody></table>`			
						console.log( id , "widtherror")	
					}
					
				}



				

			},
			//
			exporttoword( object , callback ){


				if( !this.linkInitControls ){
					return false
				}


				bus.$emit('craeteaToast', {content:"Generating document for export",title:'Exporting', variant:'info',append:true, sup:''})

				//$('#moreMisc-1')[0].click()
				//this.exportingtoword = true

				var bodytext = this.linkInitControls?.getEditor().html.get()


				let self = this

				const newDiv = document.createElement("div");
			 	newDiv.innerHTML = bodytext

			 	let imgs = $('img', newDiv )

			 	imgs.each( function( key, thisimg ){

			 		if( thisimg.src.includes("s3-eu") ){

			 			var parent = $(thisimg).parents('.fr-img-space-wrap')
			 			var caption = $(thisimg).siblings('.fr-inner')
			 			var small = $('<p></p>')
			 			//var br = $('<br>')


			 			var img = new Image();
			 			thisimg.style = ""
			 			thisimg.width = thisimg.width * .75
			 			thisimg.align = "left"
			 			thisimg.removeAttribute("align")



			 			$(thisimg).insertBefore(parent)
			 			small.append(caption)
			 			small.insertAfter(thisimg)
			 			//$(br).insertAfter(small)

			 			$(parent).remove()

			 		}

			 	})

			 	/*let inners = $('.fr-inner', newDiv )

			 	inners.each( function( key, thisinner ){

			 		if( thisimg.src.includes("s3-eu") ){
			 			var img = new Image();
			 			thisimg.style = ""
			 			thisimg.width = thisimg.width * .75
			 			thisimg.align = "left"
			 		}

			 	})*/

			 	


			 	bodytext = newDiv.innerHTML

			 	//prompt( "", bodytext )

			 	let newfilename = this.wordfilename( self.newsletter.name )


			 	Newslettersapi.Docx( { template:this.newsletter.template, filename:newfilename, body:bodytext, t:this.token} ).then( function( response ){

			 		//console.log( self.newsletter.name, "xxxxxxx")

			 		if( response.data?.ok ){

			 			self.exportedwordlocation = response.data.apidomain + '/static/exports/' +self.newsletter.template+ '/' + newfilename

			 			//response.data.newfilename

			 			
			 			//self.generatedlinkfilename = "xxxxxxxxxxx.docx"

			 			setTimeout( function(){

							location.href = self.exportedwordlocation
							
							let btn = document.getElementById('exportword-1');

							if( btn && btn.classList ){
								btn.classList.remove("fr-disabled");
							}else{
								let btns = document.querySelectorAll('[data-cmd="exportword"]');

								for (let i = 0; i < btns.length; i++) {
									btn = btns[i]
									
									if( btn && btn.classList ){
										btn.classList.remove("fr-disabled");
									}
								}
								
							}  

			 				//let link = document.getElementById('exportingtowordlocation');
			 				//link.click()


			 				//self.exportingtoword = false


			 			},50)

			 			
			 			//self.exportingtoword = false

			 			//
			 		}else{

			 			self.exportingtoword = false
			 		}


			 	}).finally( function( response ){
			 		//self.exportingtoword = false
			 	})


			},		

			tocolorimage( cxp ){

				let toreturn = ""

				if( cxp < 5 ){
					toreturn = "green"
				}else if( cxp >= 5 && cxp < 10 ){
					toreturn = "yellow"
				}else{
					toreturn = "red"
				}

				return toreturn
			},

			manualuploadtoS3( base64image, key, callback ){

				//console.log("try upload manual")

				var md5 = require('md5');

				let keystr = ""

				if( key ) {
					keystr = key
				}

	            //let base64image = base64image
	            let nowstring = performance.now()
	            let imagekey = md5( keystr + nowstring )

	            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
	            var data = {
	            Key: imagekey + ".jpg" , 
	            Body: buf,
	            ContentEncoding: 'base64',
	            ContentType: 'image/jpeg'
	            };


	            s3Bucket.putObject(data, function(err, data){
	              if (err) { 
	                
	                console.log(err);
	                console.log('Error uploading data: ', data); 
	              
	              } else {
	              	if( callback ){
	              		callback( imagekey )
	              	}
	              	
	              }

	            })
			},


			renderexternalimages( callback ){

				//this.waitingforimages = true


				this.loadedimages = 0

				let self = this

				Userapi.Masterplangraphimage( this.cockpitid, "" , this.token, "newsletter", "", "621","doc" ).then( function(response){

					let base64image = response.data.graphimage

					self.manualuploadtoS3( base64image , "masterplanimage" , function( imagekey ){

						let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + imagekey + ".jpg"
		                self.masterplanimage = graphimageurl + "?t=" + Math.floor(Math.random() * 10000000000)

		                self.loadedimages += 1

		                console.log( "masterplan loaded" )

		                /*var img = new Image();
		                img.src = self.masterplanimage

		                img.onload = function (event)
						{
						    console.log("natural:", img.naturalWidth, img.naturalHeight);
						    console.log("width,height:", img.width, img.height);
						    console.log("offsetW,offsetH:", img.offsetWidth, img.offsetHeight);
						}*/
					})

				})

				Userapi.Stakeholdergraphimage( this.cockpitid, this.token ).then( function(response){

					let base64image = response.data.graphimage

					self.manualuploadtoS3( base64image , "stakeholderimage", function( imagekey ){

						let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + imagekey + ".jpg"
		                self.stakeholderimage = graphimageurl + "?t=" + Math.floor(Math.random() * 10000000000)

		                self.loadedimages += 1

		                console.log( "stakeholder loaded" )
					})

				})


				Userapi.Risksgraphimage( this.cockpitid, "" , this.token ).then( function(response){

					let base64image = response.data.graphimage

					self.manualuploadtoS3( base64image , "riskimage", function( imagekey ){

						let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + imagekey + ".jpg"
		                self.riskgraphimage = graphimageurl + "?t=" + Math.floor(Math.random() * 10000000000)

		                self.loadedimages += 1

		                console.log( "risk loaded" )
					})

				})

				//console.log( "check")
				//console.log( this.pngdump, "check")

				if( this.pngdump ){


					//console.log( "try pngdump")

					this.manualuploadtoS3( this.pngdump , "projectcharts", function( imagekey ){

						let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + imagekey + ".jpg"
	                	self.objectivesgraphimage = graphimageurl + "?t=" + Math.floor(Math.random() * 10000000000)

	                	self.loadedimages += 1


	                	console.log( "projectcharts loaded" )
					})

				}else{
					self.loadedimages += 1
				}

				
				//console.log( "check")
				//console.log( this.riskpngdump, "check")
				if( this.riskpngdump ){

					//console.log( "try riskpngdump")

					this.manualuploadtoS3( this.riskpngdump , "riskcharts", function( imagekey ){

						let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + imagekey + ".jpg"
	                	self.riskchartimage = graphimageurl + "?t=" + Math.floor(Math.random() * 10000000000)

	                	self.loadedimages += 1

	                	console.log( "riskcharts loaded" )
					})

				}else{


					let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/sdfsdfsdfsdofosdfosdfosdkfosdf.jpg"
	                this.riskchartimage = graphimageurl + "?t=" + Math.floor(Math.random() * 10000000000)

					setTimeout( function(){				


						self.loadedimages += 1

					}, 200)

					
				}



				/*const myPromise = new Promise((resolve, reject) => {
				    // do something async
				    resolve(); // fulfilled
				    reject(); // rejected
				});*/




			},


			rendercurrentmilestones(){


				function sortbydate(a,b) {

				//var adate = new Date(a.indicatordate)
				//var bdate = new Date(b.indicatordate)

				if ( new Date(a.eventdate) < new Date(b.eventdate) )
					return -1;
				if ( new Date(a.eventdate) > new Date(b.eventdate)  )
					return 1;
				return 0;
			}

			let self = this

			let toreturn = []

			if( this.cockpit?.masterplan ){

				this.cockpit.masterplan.forEach(function ( track ) {

					if( track.milestones && !track.hidden ){

						track.milestones.forEach(function ( milestone ) {

							milestone.tracklabel = track.label

							//let timeperiodend

							let evdate = new Date(milestone.eventdate).getTime()

							if( evdate >= new Date(self.timeperiodstart) && evdate <= new Date(self.timeperiodend)  ){

								toreturn.push(milestone)

							}
							
						})
					}

					
				})


			}

			toreturn.sort(sortbydate);

			this.currentmilestones = toreturn
		},

		renderfuturemilestones(){


			function sortbydate(a,b) {

				//var adate = new Date(a.indicatordate)
				//var bdate = new Date(b.indicatordate)

				if ( new Date(a.eventdate) < new Date(b.eventdate) )
					return -1;
				if ( new Date(a.eventdate) > new Date(b.eventdate)  )
					return 1;
				return 0;
			}


			let self = this

			let toreturn = []

			if( this.cockpit?.masterplan ){

				this.cockpit.masterplan.forEach(function ( track ) {

					if( track.milestones && !track.hidden ){

						track.milestones.forEach(function ( milestone ) {

							milestone.tracklabel = track.label

							let evdate = new Date(milestone.eventdate).getTime()

							if( evdate > new Date(self.timeperiodend) ){

								toreturn.push(milestone)

							}
						})
					}

					
				})


			}

			toreturn.sort(sortbydate);

			//return toreturn

			this.futuremilestones = toreturn
		},

			renderstakeholders(){

				let self = this


				function sortbypxi(a,b) {

					var apxi = a.power * a.interest
					var bpxi = b.power * b.interest

					if ( apxi > bpxi )
						return -1;
					if ( apxi < bpxi  )
						return 1;
					return 0;
				}


			
				if( this.cockpit?.stakeholders ){

					let stakeholders = this.cockpit.stakeholders

					stakeholders.forEach(function ( stakeholder ) {

						if( stakeholder.interest > 50 && stakeholder.power > 50 ){							

							self.stakeholdersmanage.push(stakeholder)	

						}

						if( stakeholder.interest <= 50 && stakeholder.power > 50 ){

							self.stakeholderssatisfied.push(stakeholder)

						}

						if( stakeholder.interest > 50 && stakeholder.power <= 50 ){


							self.stakeholdersinformed.push(stakeholder)							

						}

						if( stakeholder.interest <= 50 && stakeholder.power <= 50 ){

							self.stakeholdersmonitor.push(stakeholder)

						}
					})

					self.stakeholdersmanage.sort(sortbypxi);
					self.stakeholderssatisfied.sort(sortbypxi);
					self.stakeholdersinformed.sort(sortbypxi);
					self.stakeholdersmonitor.sort(sortbypxi);


					//this.stakeholders = stakeholders


				}



			},


			renderrisksother(){


				/*function sortbycxp(a,b) {

					//var adate = new Date(a.indicatordate)
					//var bdate = new Date(b.indicatordate)

					if ( a.cxpcolor > b.cxpcolor )
						return -1;
					if ( a.cxpcolor < b.cxpcolor  )
						return 1;
					return 0;
				}*/



				let self = this

				let toreturn = []

				

				if( this.cockpit?.risks ){

					let risks = this.cockpit.risks

					risks.forEach(function ( risk ) {

						//console.log("xxxxxx")

						let include = 0

						if( isNaN( new Date(risk.proximity) - new Date() )  ){
							include += 1
							
						}

						if ( !risk.mitigatingaction || risk.mitigatingaction == "" ) {
							include += 1
							//console.log("action")
						}

					//	console.log( risk.label , include)

						if( include > 0 ){
							toreturn.push(risk)						
						}
						
					})



				}

				this.risksother = toreturn

			},


			renderriskstop(){


				function sortbycxp(a,b) {

					//var adate = new Date(a.indicatordate)
					//var bdate = new Date(b.indicatordate)

					if ( a.cxp > b.cxp )
						return -1;
					if ( a.cxp < b.cxp  )
						return 1;
					return 0;
				}



				let self = this

				let toreturn = []

				let cntr = 0

				if( this.cockpit?.risks ){

					let risks = this.cockpit.risks

					risks.sort(sortbycxp);

					risks.forEach(function ( risk ) {

						if( cntr < 3 ){
							toreturn.push(risk)
						
							cntr += 1
						}

						
					})



				}

				this.riskstop = toreturn

			},


			renderrisksclose(){
		///riskstop(){


				function sortbyproximitydate(a,b) {

					var adate = new Date(a.proximity)
					var bdate = new Date(b.proximity)

					if( isNaN( new Date(adate) - new Date() )  ){
						adate = new Date("1970-01-01")
					}

					if( isNaN( new Date(bdate) - new Date() )  ){
						bdate = new Date("1970-01-01")
					}

					if ( new Date(adate) < new Date(bdate) )
						return -1;
					if ( new Date(adate) > new Date(bdate) )
						return 1;
					return 0;
				}



				let self = this

				let toreturn = []

				let cntr = 0

				if( this.cockpit?.risks ){

					let risks = this.cockpit.risks

					risks.sort(sortbyproximitydate);

					risks.forEach(function ( risk ) {

						if( cntr < 3 && ( new Date(risk.proximity) >= new Date() ) ){
							toreturn.push(risk)
						
							cntr += 1
						}

						
					})



				}

				this.risksclose = toreturn

			},

			momentdate( indicatordate ){

				return moment(indicatordate).format('YYYY-MM-DD')

			},

			dumpriskpng( imageuri  ){
				//console.log( "dumpriskpng", imageuri)
				this.riskpngdump = imageuri 
			},

			dumppng( imageuri ){
				//console.log( "dumppng", imageuri)
				this.pngdump = imageuri
			},
			openpopoverinit(){
				setTimeout(function(){self.errorloadingpopover = true},100)
			},
			onpopovershow(){
		  		let self = this

		  		this.$nextTick(function(){

		  			$('#errormessagepopover').on('click', function(event){
		  			//	console.log('test')
		  				event.stopPropagation();
		  			})
		  		})
		  	},  

			replycomment(){},
			updatepages(){},
			commentposted(){},
			getWindowWidth(){
		      //console.log('hello')
		      var hh = $('.modal-header')
		      console.log()
		      if( window.innerWidth < 786 ){
		      	this.devisetype= 'mobil'
		      }else if(window.innerWidth < 1024){
		      	this.devisetype = 'tablet'
		      }else{
		      	this.devisetype = 'desktop'
		      }

		  },

		  /*getWindowHeight(){
		      //console.log('hello')
		      var hh = $('.modal-header')
		      console.log()
		      if( window.innerWidth < 786 ){
		      	this.devisetype= 'mobil'
		      }else if(window.innerWidth < 1024){
		      	this.devisetype = 'tablet'
		      }else{
		      	this.devisetype = 'desktop'
		      }

		  },*/

		  getcomments( newsletterid ){

 
	         var self = this
	 
		Cockpitsapi.Comments( { context:this.orgkey, type:"newsletter", iid:newsletterid, "token": this.token } )
			.then( function(response){
				self.comments = response.data.comments
			})
			.catch(error => console.log(error))
			.finally( () => {

			//do everything
			//self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }

			})

		  },

		  inittabs(){
/*
		  	let self = this


		  	$('a.newslettersteps[data-toggle="tab"]').on('shown.bs.tab', function (e) {

		  		var dest = e.target.href.split("#")


		  		if( dest[1] == 'create' ){

		  			self.sendnotification = false

		  			newsletterlist.publishconfirmation = false

		  			$(".tooltip").tooltip("hide");
		  			$("#newslettercreateform")[0].reset()

                    //newsletterlist.newsletter = {}
                    //self.page = 'stillloading'


                    self.page = 'overview'
                    self.maxstep = 1

	                ///// reload tables
					////// save newsletter first                   

				}



				if( dest[1] == 'preview' ){

					self.sendnotification = false

					newsletterlist.publishconfirmation = false

					$(".tooltip").tooltip("hide");
                    //newsletterlist.page = 'stillloading'

                    ///save newsletter
                    /// format headings
                    /// build body text

                    self.page = 'preview'
                    self.maxstep = 4

                }

                if( dest[1] == 'publish' ){

                	self.sendnotification = false

                	$(".tooltip").tooltip("hide");
                    //newsletterlist.page = 'stillloading'


                    

                    self.page = 'publish'
                    self.maxstep = 4

                    /// save newsletter
                    /// format headings
                    /// build body text



                }



            })

*/
		  },

		  reloadData(){},
		  loadmemberlist(){},
		  makeactive(){},
		  addstatus(){},
		  objectivesettings(){},
		  openmeasureinmodal(){},

		  

		  dopublish( newsletterid ){

		  	this.updatedraft()

		  	let self = this

		  	Newslettersapi.Publish( newsletterid, this.sendnotification, this.token)
		  	.then( function(response){

		        		//self.page = 'overview'
	                  	//self.maxstep = 1
	                  	//self.loadnewsletters()
	                  	self.publishconfirmation = true
	                  	self.publisheddone = true
	                  	self.publishmodalshow = false

				        bus.$emit('craeteaToast', {content:'Your report has been published.',title:'Success', variant:'info',append:false, sup:''})

	                  }).finally( () =>{
	                  	bus.$emit('changenewsletters',true)
	                  	self.mode = 'ispublished'
	                  })

	              },

	              dopublishconfirmation(){},

	              trydeletenewsletter(){
	              	//this.newsletter = this.activenewsletter
	              	this.showdeleteactivenewsletter = true
	              },

	              deleteactivenewsletter( newsletter ){


	              	this.showdeleteactivenewsletter =false
	              	let self = this

	              	Newslettersapi.Delete( newsletter._id, this.token)
	              	.then( function(response){

	              		self.doneedit()
	              		self.page = 'overview'
	              		self.maxstep = 1
	              		

	              	}).finally( () =>{
	              		bus.$emit('changenewsletters')
	              		this.isshown = false
	              	})
	              },


	              opennewsletterforediting( newsletterid , forceopen ){



	              	this.modalsize = "xl"
	              	this.modalupdate++


	              	this.newsletterid = newsletterid
	              	//console.log(newsletterid, this.loggedinuser)
					let self = this

				Newslettersapi.Openforedit( newsletterid, this.token, forceopen )
				.then( function(response){

					//console.log("zzzzz")


					

					if( response.data.ok && response.data.newsletter ){    
						if( response.data.newsletter.lock.islocked  && (  response.data.newsletter.lock.userid != self.loggedinuser._id ) ){

							self.activelockedto = response.data.newsletter.lock

						}else{

							self.pagemode = 'ready'

							self.activelockedto = {}

							self.page = "editor"	
			              	self.drafttingnewslettertabs = true
			              	self.mode = "editor"
						  	self.maxstep = 4


							self.newsletter = response.data.newsletter

							if( self.newsletter.published.date ){
								//self.$router.push("/411")

								var tourl = "/" + self.orgkey + "/projects/" + self.cockpitid + "/reportsview?nid=" + self.newsletter._id
								self.$router.push( tourl )

							}

							//document.title ='Edit - '+  self.newsletter.name+' | Flowe'
						}

						
		          	//self.editorcontent = 
		          	
		          	//self.reset()
		           	//self.loadnewsletters()
		           }else{

		           	//fourofoured
		           	//self.$router.push("/400")

		           }

		       }).finally(()=>{
		          
		         // console.log("ffafasf")
		        })


		     //  console.log("kaskdkasd")
			},

			opennewsletterforcreating(){
				this.modalsize = 'md'
				this.modalupdate++
				this.pagemode = 'ready'
				this.mode = "creating"
			},	

			initializefroala: function(initControls) {


				//alert('aa')
				//this.froalaconfig.height = this.windowheight - 200//"220vh";

				//this.froalaconfig.height = "5000px"

				//let self = this

				
				let self = this 

				//alert( self.linkInitControls )

				setTimeout(function(){

					//if( self.linkInitControls ){

					self.linkInitControls = initControls;
					self.linkInitControls.initialize()

					self.newsletterviewerdata = ""//self.linkInitControls.getEditor().html.get()

					//}

					setTimeout(function(){

						if( !self.linkInitControls ){
							self.froalaupdate++
						}

					}, 200)

				}, 100)



				
			},
			

			opennewsletterforviewing(nid){

				this.newsletterid = nid
				/////////////**
				this.isshown = false
				bus.$emit('onopennewsletterforviewing',nid,this.cockpitid)

			},

			getobjectives(){


				let self = this
				
				this.cockpit = {}
				
				Cockpitsapi.Cockpits( { "_id":this.cockpitid, "token": this.token } )
				.then( function(response){

					//console.log( response.data, "cccc")

					if( response.data.ok || !response.data.ok ){
						
						self.cockpit = response.data.cockpits

						if( !self.cockpit.isactive ){
							self.doneedit(true)
						}

						self.cockpitname = self.cockpit.name

						self.rendercurrentmilestones()
						self.renderfuturemilestones()
						self.renderriskstop()
						self.renderrisksclose()
						self.renderrisksother()

						self.renderstakeholders()
						
						if( self.viewer == true ){

						}else if(self.mode == "editor"){

							setTimeout( function(){

								self.inittabs()

							}, 200)
	

						}						

					}
				})
				.catch(error => console.log(error))

				.finally( () => {
					self.pagemode = 'ready'

				})

			},

			initpage(){

				let self = this

				this.stakeholdersmanage = []
				this.stakeholderssatisfied = []
				this.stakeholdersinformed = []
				this.stakeholdersmonitor = []


				//this.froalaconfig.events.contentChanged = function () {self.updatedraft(self) }
				this.periodStart = null
				this.periodend = null

				this.getobjectives()

			},

			updatedraft(){

				if( !this.linkInitControls ){
					return false
				}

				let self = this
				this.savingnewsletter = 'saving'
				var bodytext = ""

				if( this.linkInitControls && this.linkInitControls.getEditor() ){
					bodytext = this.linkInitControls.getEditor().html.get()	
				}

				this.newsletterviewerdata = this.linkInitControls?.getEditor().html.get()	
				this.errorloading = false 
				Newslettersapi.Save( { "t":this.token, "newsletterid":this.newsletter._id,"bodytext":bodytext} )
				.then( function(response){

					if( response.data.ok ){
						self.savingnewsletter = 'saved'
						self.savedtime = new Date()
					}else{
						self.savingnewsletter = ''
					}

				})
				.catch(error => {
					self.savingnewsletter = 'notsaved'
					console.log(error);self.errorloading = true
				})
				.finally( () => {

					setTimeout( function(){
						self.savingnewsletter = ''
					}, 4000)

						
					})
			},

			precreatedraft(){

				this.isworking = false

				let self = this

				 if( this.template == "projectstatusreporten" || this.template == "projectstatusreportdk" ){

				 	this.waitingforimages = true
				 	this.renderexternalimages()

				 }else{

				 	this.createdraft()
				 }

				

				
			},

			createdraft(){


				let self = this

				var from = new Date(this.periodStart)
				var to = new Date(this.periodend)

				from = new Date(from.setHours(0))
				to = new Date( to.setHours(23) )
				to = new Date( to.setMinutes(59) )

				var bodytext = this.loadtemplate( this.cockpit, this.template, this.newslettertitle, from, to  )  

				bodytext = bodytext.replace( "|insertthegraphhere|", '<img src="' + this.pngdump +'" alt="Graph">' )

				//this.timeperiodstart = from//.getTime()
				//this.timeperiodend = to//.getTime()

				from = moment(from).format('X') 
				to = moment(to).format('X')
				

				Newslettersapi.Save( { "t":this.token, "title":this.newslettertitle, "newsletterid":"", "cockpitid":this.cockpitid, "template":this.template, "datefrom":from, "dateto":to, "bodytext":bodytext  } )
				.then( function(response){

					if( response.data.ok ){

						//self.reset()
						//self.drafttingnewslettertabs = true						
						/*self.$nextTick(function(){							
						})	
						*/

						

						self.isworking = false

						setTimeout( function(){

							var tourl = "/" + self.orgkey + "/projects/" + self.cockpitid + "/reportsedit?nid=" + response.data.newsletterid + "&page=newsletter"
							self.$router.push( tourl )

							self.isshown = false

							setTimeout( function(){
								
								self.modalsize = "xl"
								self.modalupdate++

								self.isshown = true
								self.opennewsletterforediting( response.data.newsletterid )
							}, 1000)
							
						}, 100)


						//console.log()


						//
						//self.$func.addQuery({nid:response.data.newsletterid,page:'newsletter'},self.$route, self.$router)
						


						//self.loadnewsletters()
						// self.$emit('onlistupdate')
					}

				}).finally(()=>{
					self.$emit('onlistupdate')
				})/*.catch(()=>{

					alert('Invalid request or Page data needs to update. Page will now reload.')
					location.reload()

				})*/

				

				
			},


			reset(){

				this.isDraftingNewsletter=false
				this.newslettertitle=""
				this.periodStart=""
				this.periodend=""
				this.bodytext=""


			},

			startDrafting(){
				this.isDraftingNewsletter=true;
				$('#pills-Create-tab').removeClass('active')
				$('#pills-Editer-tab').addClass('active');
			},

			/*modalhidden(){

				let self = this

				Newslettersapi.Unlock( this.newsletterid, this.token)
			  	.then( function(response){

			  		self.froalaconfig.events.contentChanged = function () {}

					self.reset()
					self.newsletter = {}
					self.publishconfirmation = false
					self.mode = "editor"
					self.drafttingnewslettertabs = false
	 				self.pagemode = 'notready'
					self.page = 'overview'
					self.$emit('onmodalhidden')
					self.linkInitControls.destroy();
					self.$func.removeQuery(['page','nid'],self.$route,self.$router)
					self.isshown = false

			  	})

				
			},*/

			togglecreatenewsletter(){
				
				$('.toggle_createnewsletter').parent('a').toggleClass('hide')
				$('#newslettercreateform').parent('div').parent('div').toggleClass('hide')
			},

			timeFormat : function( unixtime ){

				var date = new Date( unixtime * 1000 )

				var str = ""

				if( date != "" ){
					str = moment( date ).format('YYYY-MM-DD')
				}

				return str 
			},
			dateFormat : function( obj ){

				var str = ""

				if( obj != "" ){
					str = moment( obj ).format('YYYY-MM-DD')             
				}
				//str = moment( obj ).fromNow()


				return str 
			},

			loadtemplate : function( cockpit, template, title, datefrom, dateto ){


				// always point at the public img not the static builds due to the filename changes when rebuilt

				let imgurlgreen = 'https://app.flowe.io/static/img/green.png'
				let imgurlred = 'https://app.flowe.io/static/img/red.png'
				let imgurlblue = 'https://app.flowe.io/static/img/blue.png'
				let imgurlyellow = 'https://app.flowe.io/static/img/yellow.png'
					
				var green = '<img src="'+imgurlgreen+'">'
		        var red = '<img src="'+imgurlred+'">'
		        var blue = '<img src="'+imgurlblue+'">'
		        var yellow = '<img src="'+imgurlyellow+'">'


				function compare(a,b) {

					var adate = new Date(a.indicatordate)
					var bdate = new Date(b.indicatordate)

					if ( adate < bdate)
						return -1;
					if ( adate > bdate)
						return 1;
					return 0;
				}

				var html = "<div class=\"fr-view newslettercontent_" +template+ "\">"

				if( template == "team1" ){

		         // html += "<h2 class=\"fr-deletable headings\" contenteditable=\"false\">" + cockpitview.cockpit.name + "</h2>"
		         // html += "<h5 class=\"fr-deletable headings\" contenteditable=\"false\">" + title + " (" + moment(datefrom).format('YYYY-MM-DD') + " - " +  moment(dateto).format('YYYY-MM-DD') + ")</h5>"
		         
		        //  html += "<hr class=\"headings\">"


		        html += `<p><span style="color: rgb(41, 105, 176);">Editing the report (Delete all blue text before publishing)</span></p>`
		        html += `<ol><li style="color: rgb(41, 105, 176);">All data, images, and text are editable, so you can customize the report to fit your style.</li><li style="color: rgb(41, 105, 176);">All changes are saved as you type. Clicking 'X' to close saves.</li><li style="color: rgb(41, 105, 176);">Add qualitative text to explain and reflect on the project data for the period.</li><li style="color: rgb(41, 105, 176);">Use the preview tab above to review and go to the publish tab to make the report available to all project members.</li><li style="color: rgb(41, 105, 176);">When the report is published, all project members receive an email notification and can read the report. A published report can not be edited or deleted.</li></ol>`

		        html += "<p>Dear all,</p>"
		        html += "<p>Add your welcome paragraph here...</p>"
		        /*

		        */

		        html += "<p>|insertthegraphhere|</p>"


		         $.each(cockpit.sections, function(key,s){

		         		if( s.objectivesdata && s.objectivesdata.length >0 ){
		         			html += "<div class=\"sectiongroup\"><h4>" + s.label + "</h4> <hr>" 
		         		}
		         		
		            $.each( s.objectivesdata, function( key,m ){

		            	if( m.isactive &&  m.myrole  ){

		            		html += "<div class=\"objectivegroup\"><h4>" + m.name + "</h4>" 

		            		var lateststatus = ""
		            		if( m.indicators.length > 0 ){

		              /*lateststatus = m.indicators[0]
		              html += lateststatus.message*/

		              var mindicators = m.indicators.sort(compare);

		              $.each( mindicators, function( key,i ){

		              	var indicatordate = new Date(i.indicatordate)

		              	if( datefrom <= indicatordate && dateto >= indicatordate ){
		                  //moment(lateststatus.indicatordate).fromNow()

		                  if(i.color == 'green'){
		                  	html += green
		                  } else if(i.color == 'red'){
		                  	html += red
		                  } else if (i.color == 'blue'){
		                  	html += blue
		                  } else {
		                  	html += yellow
		                  }
		                  

		                  if( i.userdetail ){
		                  	html += " " + i.userdetail.firstname + ' ' + i.userdetail.lastname
		                  }else{
		                  	html += " Author"
		                  }
		                  

		                  html += " - " + moment(i.indicatordate).format('YYYY-MM-DD') + "</p>"

		                  html += "<div class ='clearfix' style=\"margin-left: 20px;\">"

		                  html += i.message

		                  html += "</div>"
		              }

		          })

		              
		              
		          }else{
		          	html += "<p style=\"margin-left: 20px;\">No indicators available</p>"
		          }

		          html += "</div>"

		      }

		  })
		            html += "</div>"

		   })
		        if( cockpit.measuresnotinsections && cockpit.measuresnotinsections.length > 0){
		      		html += "<div class=\"sectiongroup\"><h3> Not grouped </h3> <hr>" 
		      	}
		            $.each( cockpit.measuresnotinsections, function( key,m ){

		            	if( m.isactive &&  m.myrole  ){

		            		html += "<div class=\"objectivegroup\"><h4>" + m.name + "</h4>" 

		            		var lateststatus = ""
		            		if( m.indicators.length > 0 ){

		              /*lateststatus = m.indicators[0]
		              html += lateststatus.message*/

		              var mindicators = m.indicators.sort(compare);

		              $.each( mindicators, function( key,i ){

		              	var indicatordate = new Date(i.indicatordate)

		              	if( datefrom <= indicatordate && dateto >= indicatordate ){
		                  //moment(lateststatus.indicatordate).fromNow()
		              // html += i.color == 'green' ? green : red

		               		if(i.color == 'green'){
		                  	html += green
		                  } else if(i.color == 'red'){
		                  	html += red
		                  } else if (i.color == 'blue'){
		                  	html += blue
		                  } else {
		                  	html += yellow
		                  }

		                  if( i.userdetail ){
		                  	html += " " + i.userdetail.firstname + ' ' + i.userdetail.lastname
		                  }else{
		                  	html += " Author"
		                  }
		                  

		                  html += " - " + moment(i.indicatordate).format('YYYY-MM-DD') + "</p>"

		                  html += "<div style=\"margin-left: 20px;\">"

		                  html += i.message

		                  html += "</div>"
		              }

		          })

		              
		              
		          }else{
		          	html += "<p style=\"margin-left: 20px;\">No indicators available</p>"
		          }

		          html += "</div>"

		      }

		  })
		            html += "</div>"

		            html += "<br><p>In conclusion, I would like to ...</p>"



		        }


		        if( template == "projectstatusreporten" || template == "projectstatusreportdk" ){

		        	let textstr = document.getElementById("progressstatusreporttemplate");

		        	html += textstr.innerHTML    	

		        }

		        if( template == "blank" ){


					html += `<p><span style="color: rgb(41, 105, 176);">Editing the report (Delete all blue text before publishing)</span></p>`
		        	html += `<ol><li style="color: rgb(41, 105, 176);">All data, images, and text are editable, so you can customize the report to fit your style.</li><li style="color: rgb(41, 105, 176);">All changes are saved as you type. Clicking 'X' to close saves.</li><li style="color: rgb(41, 105, 176);">Add qualitative text to explain and reflect on the project data for the period.</li><li style="color: rgb(41, 105, 176);">Use the preview tab above to review and go to the publish tab to make the report available to all project members.</li><li style="color: rgb(41, 105, 176);">When the report is published, all project members receive an email notification and can read the report. A published report can not be edited or deleted.</li></ol>`

		        	html += "<p>Dear all,</p>"
		        	html += "<p>Add your welcome paragraph here...</p>"
		        	html += "<br><p>In conclusion, I would like to ...</p>"

		        }

		        html += "</div>"

		        return html

		    },


		}
	}

</script>